body {
    background-color: #fff;
    font-family: Lato,sans-serif !important;
    color: #212121;
    font-size: 16px;
    font-weight: 400 !important;
}

h1 {
    margin-top: 0;
    font-size: 48px;
    line-height: 56px;
    text-transform: uppercase;
}

h1, h2, h3 {
    margin-bottom: 10px;
    font-family: 'Playfair Display',sans-serif;
    font-weight: 400;
}

    h1 p {
        margin-bottom: 0;
    }

h4 {
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
}

h4, h5, h6 {
    margin-bottom: 10px;
    font-weight: 700;
}

.p {
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 0;
}

.successmessage a {
    color: #92a8b4 !important;
}

    .successmessage a:hover {
        color: #fff !important;
    }

.header + .section,
.the-website.pf-promotion-strip-enabled .ecs-main-container {
    padding-top: 20px !important;
}

.topheader-dark {
    background-color: #fff;
    z-index: 9;
    position: relative;
}

.the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
    padding-top: 0 !important;
}

.pf-promotion-container {
    float: none;
    height: auto;
    padding: 0 20px;
}

#pf-product-list-page.pf-page-wrapper {
    padding-left: 20px;
    padding-right: 20px;
}

.ecs-main-container-nbp {
    position: relative;
    float: left;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.mbss-content.p {
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 0;
}

.webui-popover-content {
    color: #000 !important;
}

.banner {
    height: 230px;
}

.banner-text-large {
    margin-bottom: 10px;
    font-family: 'Playfair Display',sans-serif;
    font-size: 36px;
    line-height: 46px;
    font-weight: 300;
    letter-spacing: 1px;
}

.banner-container {
    text-shadow: none;
}

    .banner-container h1 {
        text-transform: uppercase;
    }

.section {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: -webkit-linear-gradient(270deg,rgba(255 255 255),rgba(255 255 255)),url(../images/wavecut.png);
    background-image: linear-gradient(180deg,rgba(255,255,255,.7),rgba(255,255,255)),url(../images/wavecut.png);
    background-position: 0 0,0 0;
    font-weight: 300;
    background-color: transparent;
}

.containerbanner {
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-top: 55px;
    padding-bottom: 50px;
    font-size: 19px;
    line-height: 27px;
}

    .containerbanner h1 {
        text-transform: uppercase;
    }

.brandsection._2 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.brandsection._2 {
    position: relative;
    background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%,rgba(0,0,0,.5),transparent),url(../images/1_19.jpg);
    background-image: radial-gradient(circle farthest-corner at 50% 50%,rgba(0,0,0,.5),transparent),url(../images/1_19.jpg);
}

.brandsection {
    position: relative;
    padding: 80px 20px;
    background-color: #92a8b4;
    background-image: url(../images/1_22.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    text-shadow: 1px 1px 8px rgba(0,0,0,.3);
}
.largetext {
    margin-top: 10px;
    font-family: 'Playfair Display',sans-serif;
    font-size: 58px;
    line-height: 66px;
    font-weight: 400;
    letter-spacing: 1px;
}

.containerslider, .largetext {
    color: #fff;
    text-align: center;
}

.img, .largetext {
    margin-bottom: 10px;
}

    .largetext, .largetext._2, .map, .mediumtext, .open-text {
        display: block;
    }

.button, .dropdown, .largetext, .navlink, .new, .open-text, .subtitle, .title {
    text-transform: uppercase;
}

.button-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
}

    .button-wrapper.solid {
        margin-top: 40px;
    }

    .button-wrapper a:not(.re-icon):not(.mbss-btn-icon):not(.re-button), .button-wrapper input {
        margin-bottom: 5px;
        border: 1px solid #fff;
        background-color: transparent;
        -webkit-transition: color .5s ease,background-color .5s ease;
        transition: color .5s ease,background-color .5s ease;
        text-shadow: none;
    }

    .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px 26px;
        font-size: 12px;
        line-height: 21px;
        background-color: transparent;
        text-transform: uppercase;
        border: 1px solid #fff;
        color: #fff;
        -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
        transition: color .5s ease,background-color .5s ease,border .6s ease;
    }

a {
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
    color: hsla(0,0%,100%,.6);
    text-decoration: underline;
}

.w-nav-button, .w-tab-link, a {
    cursor: pointer;
}

.midnav {
    background-color: #363636;
    height: 100px;
}
.midnav div#search-form a#prod-search-btn {
    background-color: #363636;
    border-top: none;
    border-right: none;
    border-left: none;
}
/* Blog pages */
.categorylink {
    color: hsla(0,0%,100%,.7) !important;
}

    .categorylink:hover {
        color: #92a8b4 !important;
    }

.tags-container .tag {
    border: 1px solid #fff !important;
    background-color: transparent !important;
    color: hsla(0,0%,100%,.7) !important;
}
#header-top .nav.w-nav.navigation {
    border-top-color: #92a8b4 !important;
    border-top: 1.5px solid;
}
.midnav #prod-search-tbx {
    border-top: none;
    border-left: none;
}
#prod-search-tbx,
#prod-search-tbx-mobile {
    padding-right: 55px;
}

.navigation {
    width: 100%;
    background-color: #363636;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.title-wrapper {
    margin-bottom: 70px;
    padding-right: 120px;
    padding-left: 120px;
    font-size: 22px;
    line-height: 25px;
}

    .title-wrapper h1 {
        font-size: 36px;
        line-height: 44px;
        text-align: center;
    }

.header {
    position: relative;
}

.pf-product-list-item, .pf-recent-related-product-item {
    background-color: transparent;
}

.productwrapper {
    position: relative;
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
    padding-left: 0;
    border: 1px solid #898989;
    background-color: #212121;
    color: #999;
    text-decoration: none;
}

#platformModalConfirmation {
    background: #333;
}

#platformConfirmationModalText i {
    color: white;
    font-size: 3em;
    margin-bottom: 20px;
}

#platformConfirmationModalText p {
    color: #fff;
    margin-bottom: 20px;
}

#platformModalConfirmation {
    background: #212121;
    padding: 30px;
}

#pf-product-listing .flex-col {
    width: 24%;
    margin: .5% .5% 0 .5%;
}

.pf-product-list-options select {
    background-color: transparent;
    border-radius: 0;
    background-repeat: no-repeat;
    background-image: initial;
    background-size: initial;
}

select#node, select#product-sort, select#product-limit {
    background-position: 98% 50%;
    display: block;
    height: 45px;
    padding: 1px 20px 1px 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    position: relative;
    margin-top: -13px;
    margin-bottom: 0;
    float: left;
    border-radius: 0;
    margin-right: 10px;
}

.productOptions, select#node, select#product-sort {
    min-width: 126px;
    width: auto;
    -moz-appearance: none;
    font-style: normal;
    background-repeat: no-repeat;
    -webkit-appearance: none;
}

.w-select {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    padding-right: 20px;
    background-position: 98% 50%;
    background-repeat: no-repeat;
}

.tab.w--current, .tab:hover {
    background-color: #92a8b4 !important;
    text-decoration: none;
    color: #fff !important;
}

.select {
    display: block;
    margin-bottom: 15px;
    color: #818181;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
}

.w-select, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 48px;
    margin-bottom: 10px;
    padding: 0 20px 0 12px;
    border: 1px solid #898989;
    color: #898989 !important;
    -webkit-transition: border .5s ease;
    transition: border .5s ease;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    border-radius: 0;
}

.w-select, a#discount-btn, input, input#discount-input-tbx {
    background-color: transparent;
}

#prod-search-btn, .dropdown-toggle, #prod-search-btn-mobile {
    width: 48px;
    height: 48px;
    border: 1px solid #898989;
}

    #prod-search-btn.pf-btn.pf-btn-primary, #prod-search-btn-mobile.pf-btn.pf-btn-primary {
        border: 1px solid #898989 !important;
        margin: 0;
    }

.dropdown-toggle {
    background-color: #363636 !important;
    text-transform: uppercase;
    color: #fff;
    padding: 15px 20px;
}

.home-image {
    min-width: 100%;
    -webkit-transition: -webkit-transform .9s ease;
    transition: -webkit-transform .9s ease;
    transition: transform .9s ease;
    transition: transform .9s ease,-webkit-transform .9s ease;
    overflow: hidden;
}

    .home-image:hover {
        transform: scale(1.05);
    }

    .home-image .btn-custom.btn {
        top: 564px !important;
        left: 24px !important;
    }

.home-img-caption {
    top: 50%;
    z-index: 9000;
    min-height: 50px;
    padding: 20px 20px 30px;
    background-color: rgba(0,0,0,.41);
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    color: hsla(0,0%,100%,.9);
    font-size: 21px;
    line-height: 29px;
    text-align: center;
}

.gallery-title, .home-img-caption {
    position: absolute;
    right: 0;
    left: 0;
}

#pf-gallery-image-products {
    flex-wrap: wrap;
}

    #pf-gallery-image-products .flex-col {
        width: 25%;
    }

.mid-footer {
    background-color: #363636 !important;
}

    .mid-footer .flex-child {
        border: none;
    }

.img-overlay {
    border: none;
}

.w-checkbox:after, .w-checkbox:before, .w-container:after, .w-container:before, .w-radio:after, .w-radio:before, .w-row:after, .w-row:before {
    display: table;
    content: " ";
}

.w-checkbox:after, .w-checkbox:before, .w-container:after, .w-container:before, .w-radio:after, .w-radio:before, .w-row:after, .w-row:before {
    display: table;
    content: none;
}

.postcode-lookup-btn, .w-checkbox:after, .w-clearfix:after, .w-container:after, .w-embed:after, .w-radio:after, .w-row:after, .w-slider {
    clear: both;
}

.brandsection h2 {
    font-size: 36px;
    line-height: 66px;
}

h2 {
    margin-top: 10px;
    font-size: 34px;
    line-height: 42px;
}

.row-service {
    margin-top: 0;
    margin-bottom: 10px;
}
.service-column {
    margin-bottom: 5px;
    padding:0;
}
.begin-wrapper, .brandsection, .brandsrow, .icon-features, .input.inline.qty, .open, .open-text, .product-price, .productinfo, .productwrapper, .project-column, .service-column, .title-wrapper {
    text-align: center;
}
.product-name, .topfooter, .white {
    font-weight: 300;
}
.product-name {
    margin-top: 30px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
}

.pf-product-list-item .pf-product-code {
    margin-bottom: 7px;
    font-family: Lato,sans-serif;
    color: #92a8b4;
    line-height: 18px;
    font-size: 18px;
}

.product-name, .topfooter, .white {
    font-weight: 300;
}

.social-link:hover {
    background-color: #92a8b4;
    color: #fff;
}

.footerelementwrapper {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    padding-bottom: 20px;
    padding-top: 20px;
    margin:0;
}

.footerelementwrapper a {
    display: block;
}

.footerelementwrapper h3 {
    font-size: 36px;
    font-weight: 400;
}

.button:hover {
    border-color: transparent;
    background-color: #92a8b4;
    color: #fff;
}

.button, .button:hover, .navlink, .navlink.top.cart, a:hover {
    text-decoration: none;
}

.button {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px 20px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0,0%,100%,.7);
    border-radius: 0;
    background-color: transparent;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    text-shadow: none;
}

.button, .container-welcome, .navigation-menu {
    text-align: center;
}

.button, .w-nav[data-animation=over-left] .w-nav-overlay, .w-nav[data-animation=over-right] .w-nav-overlay {
    width: auto;
}

.input.inline {
    display: inline-block;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #898989;
    border-radius: 0;
    -webkit-transition: opacity .5s ease,background-color .5s ease,color .5s ease,border .5s ease;
    transition: opacity .5s ease,background-color .5s ease,color .5s ease,border .5s ease;
}

.input, .input.inline {
    background-color: transparent;
}

    .fontello, .input.inline, .sub, h3 {
        font-style: normal;
    }

.bottomfooter {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #212121;
    font-weight: 300;
}

.bottomfooter, .copyright {
    color: hsla(0,0%,100%,.6);
}

.bottomfooter, .copyright {
    color: hsla(0,0%,100%,.6);
}

.copyright, .email, .open, .socialicon, .socialicon:hover {
    text-decoration: none;
}

.services {
    padding-top: 0;
    padding-bottom: 0;
}

    .services .title-wrapper h2 {
        padding-top: 5px;
    }

.gridcat {
    background-position: initial;
}

.pf-image-collection {
    padding-top: 80px;
}

    .pf-image-collection .flex-child {
        width: 49%;
        margin-bottom: 0;
        padding-bottom: 0;
    }

.section.portfolio {
    padding: 0;
}

.section.brandsection {
    padding-top: 0;
    padding-bottom: 0;
}

.footer {
    padding-top: 60px;
    padding-bottom: 50px;
    background-color: #363636;
    color: hsla(0,0%,100%,.6);
    font-size: 13px;
    line-height: 21px;
}

.nav.w-nav {
    background-color: #363636;
}

.nav-menu {
    background-color: #363636;
    text-align: right;
}

.navlink {
    width: auto;
    padding: 28px 18px;
    border-bottom: 1px solid transparent;
    color: hsla(0,0%,100%,.7);
    font-weight: 300;
}

.button, .button:hover, .navlink, .navlink.top.cart, .navlink:hover, a:hover {
    text-decoration: none;
}
.subscribe-wrapper {
    background-color: #92a8b4;
}
.subscribe-wrapper .w-container .mbss-text {
    color: #fff;
}
.subscribe-wrapper .w-container {
    padding-left: 90px;
}
#pf-product-listing .product-match-height.productwrapper.pf-product-list-item.border, #pf-sub-cats-listing .productwrapper {
    background-color: #fff;
}
#pf-product-listing #list-products-wrapper .product-name.pf-product-name, #pf-sub-cats-listing .productwrapper .product-name.pf-product-name {
    font-family: Lato,sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #363636;
    line-height: 24px;
    margin-top: 5px;
}
#pf-sub-cats-listing .productwrapper a {
    color: #363636;
    text-decoration: none;
}
#pf-sub-cats-listing .productwrapper {
    padding:0;
    min-height:294px;
}
#pf-sub-cats-listing.section {
    padding-bottom: 25px;
    padding-top:0;
}
#pf-sub-cats-listing h2{
    display:none;
}
#pf-product-listing .pf-product-price {
    font-family: Lato,sans-serif;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    color: #363636;
    line-height: 28px;
}
.pf-product-code, .pf-product-list-item .pf-stock-info {
    color: #aaa;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}
#pf-product-listing .product-code.pf-product-code, #pf-product-listing .pf-custom-stock-message,
#pf-product-listing .pf-stock-notify-me.pf-block a {
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height:24px;
    color:#92a8b4;
}
#pf-product-listing .pf-custom-stock-message {
    color: #aaaaaa;
}
#pf-product-listing .pf-add-cart-wrapper .qty-tbx {
    background-color: #fff;
    color: #000;
}
.pf-product-list-item .pf-qty-btn-minus, .pf-product-list-item .pf-qty-btn-plus {
    border: 1px solid #666;
}
a.product-list-add-to-cart-btn.pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
    height: 40px;
    padding: 8px 22px;
    top: -1px;
}
h1.productListingMainHeading {
    font-size: 24px;
    font-weight: 600;
}
.pf-multi-level-sub-nav-wrapper .ecs-nav-group .pf-highlight-nav, .pf-multi-level-nav .pf-multi-level-sub-nav-wrapper .pf-highlight-nav .fa-chevron-right,
.pf-multi-level-sub-nav-wrapper .ecs-nav-group .pf-multi-level-subcats-wrapper .pf-subhighlight-nav {
    color: #363636;
}
.pf-multi-level-sub-nav {
    transform: scale(1,0);
    -webkit-transform: scale(1,0);
    -ms-transform: scale(1,0);
    transition: all 0.5s ease;
    opacity: 0;
    display: block;
}
.pf-multi-level-sub-nav.w--open {
    opacity: 1;
    transform: scale(1,100%);
    -webkit-transform: scale(1,1);
    -ms-transform: scale(1,1);
}
.pf-multi-level-subcats-wrapper {
    transition: all 0.5s ease;
    transform: translate(20%,0);
    display: block;
    opacity: 0;
    top: -20px;
}
.pf-multi-level-subcats-wrapper.show {
    border: 1px solid;
    border-color: #92a8b4;
    display: block;
    opacity:1;
    transform:translate(0,0);
    z-index:1;
}
.nav-menu .pf-multi-level-nav .w-dropdown-list {
    border: 1px solid #92a8b4;
    max-width: 288px;
}
.pf-multi-level-sub-nav .pf-multi-level-sub-nav-wrapper .ecs-nav-group:not(:last-child) .heading:after {
    background-color: #BFBFBF;
}
.pf-multi-level-subcats-wrapper .dropdownlink:after {
    top: 0px;
}
#prod-search-btn, .dropdown-toggle.pf-filter-item, .mid-footer {
    color: #323232;
}
.pf-product-listing .pf-filter-dropdown-icon i.fa.fa-caret-down, .pf-filter-dropdown-list i.fa.fa-caret-down {
    color: #323232;
}
#pf-product-details-page .breadcrumb {
    font-size: 16px;
    font-family: Lato,medium !important;
}
h1.pf-product-details-heading, h2.table-title.pf-product-details-sub-heading {
    font-size: 24px;
    font-family: 'Playfair Display';
    font-weight: 600;
    color: #363636;
    line-height: 32px;
}
#pf-product-details-page .breadcrumb:first-child a:last-of-type, #pf-product-list-page .breadcrumb:first-child span:last-of-type a{
    color: #000 !important;
}
.breadcrumb a.pf-normal-link {
    color: #92a8b4 !important;
}
#pf-product-listing nav.filter-dropdown-list.pf-filter-dropdown-list div div div a {
    color: #363636 !important;
    text-transform:uppercase;
    font-size:14px;
    line-height:22px;
}
#pf-product-listing nav.filter-dropdown-list.pf-filter-dropdown-list div a[href*="warehouse"] {
    color: #ff0000 !important;
}
#pf-product-details-page #pf-related-recent-products-wrapper .pf-related-list-wrapper {
    padding-left: 64px;
}
#pf-product-details-page .pf-product-details-info-wrapper .pf-disc-table tbody tr:nth-child(2n+1) {
    background-color: #fff;
}
#pf-product-details-page .pf-disc-table td, .pf-product-list-item .pf-disc-table td {
    background-color: #fff;
    color: #000;
}
#pf-product-details-page #pf-product-actions-wrapper .product-detail-price {
    font-size: 20px;
    color: #323232;
}
.pf-qty-btn.pf-qty-btn-minus, .pf-quick-view-qty-btn-minus {
    border-left: 1px solid #666;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}
.pf-qty-btn.pf-qty-btn-plus, .pf-quick-view-qty-btn-plus {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}
#pf-product-details-page .pf-qty-btn.pf-qty-btn-plus, #pf-product-details-page .pf-qty-btn.pf-qty-btn-minus {
    border: 1px solid #666;
}
#pf-product-details-page .qty-tbx {
    color: #212121;
    background-color:#fff;
}
.pf-product-listing .pf-disc-table td, .pf-disc-table th, .pf-spec-table td, .pf-spec-table th {
    color: #323232 !important;
    background-color: #fff;
}
.pf-product-image-thumb-wrapper {
    width:100%;
}
.pf-related-viewed-section .pf-disc-table, .pf-recent-viewed-section .pf-disc-table {
    margin-left: auto;
    margin-right: auto;
}
.topcategories-slider {
    display:flex;
}
.topcategories-slider.slick-initialized.slick-slider {
    display:block;
}
form#wf-form-Secure-Login-Form a[href*="Signup"] {
    color: #000 !important;
}
.pf-checkout-cart-table .qtywrapper input.quantity.qty-tbx.pf-qty-select {
    background-color: #fff;
    color: #000;
}
#pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn.pf-btn-primary.pf-btn-with-qty {
    height: 40px;
    top: -1px;
    padding:8px 22px;
}
#pf-product-details-page .pf-custom-stock-message, .ecs-row.reset-password input#Email-6 {
    color: #000;
}
.pf-related-product-zone .pf-slide-item .pf-product-name, .pf-related-product-zone .pf-slide-item .pf-product-price {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}
div#pf-product-listing {
    background-color: #fff;
}
.pf-product-listing .productwrapper .product-info-match-height .product-name.pf-product-name,
.pf-product-listing .productwrapper .product-info-match-height .custom-stock-message,
.pf-product-listing .productwrapper .product-info-match-height .pf-disc-table td{
    color: #000;
}
.pf-product-listing .productwrapper .product-info-match-height .pf-disc-table td{
    background-color: #fff;
}
.pf-stock-notify-me.pf-block a {
    color: #000 !important;
}
.topfooterarea {
    background-color: #fff;
    padding-bottom: 40px;
    padding-top: 40px;
    text-align:center;
}
.section.title-wrapper.logo {
    background-image: linear-gradient( 180deg,rgb(255 255 255),rgb(255 255 255)),url(../images/wavecut.png);
}
.pf-image-collection.top-category-images {
    padding-top: 0;
}
div.section.services.midsection {
    background-color: #fff;
    background-image: linear-gradient( 180deg,rgba(255,255,255,.7),rgba(255,255,255,.7)),url(../images/wavecut.png);
}
.mid-footer {
    padding-top: 0;
}
.pf-product-listing .pf-filter-title.ecom-filter-group {
    color: #fff;
}
.header .nav-menu a.w-nav-link.nav-link {
    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
}

i.menu-left {
    padding-right: 10px;
    padding-left: 12px;
}
.pf-multi-level-nav .pf-multi-level-sub-nav-wrapper .ecs-nav-group .pf-multi-level-dropdown .pf-highlight-nav,
.pf-multi-level-nav .pf-multi-level-sub-nav-wrapper .ecs-nav-group .w-dropdown-link .pf-highlight-nav {
    padding-left: 12px;
}
.pf-multi-level-nav .pf-multi-level-sub-nav-wrapper div:last-child a .pf-highlight-nav {
    color: #ff0000;
}
.nav-container .pf-multi-level-nav div.pf-highlight-nav {
    color: #fff;
    font-size: 16px;
    line-height: 19px;
}

.navigation .pf-multi-level-nav .pf-multi-level-heading {
    background-color: #363636;
}

.navigation .pf-multi-level-nav .pf-multi-level-heading:hover, .navigation .pf-multi-level-nav:hover, .pf-multi-level-heading.w-dropdown-toggle.nav-link.nav-dropdown.w--open, .pf-multi-levelsubcats-wrapper .dropdownlink:after {
    background-color: #92a8b4;
}

.title-wrapper.logo.topcategories {
    margin-top: 0;
    border-bottom: 4px solid;
    border-bottom-color: #363636;
    background-color: #fff;
    padding-bottom: 28px;
    margin-bottom: 0px;
}
.ecs-page-wrapper .input {
    color: rgb(0, 0, 0);
}
.menu-buttons-salesagent-right {
    margin-top: -15px;
    float: right;
}
.allmenubuttons .navlink {
    padding: 10px 18px;
}
.allmenubuttons {
    float:right;
}
.menu-buttons-not-signed-in {
    float: right;
    margin-top: -20px;
}
.menu-buttons-salesagent-left {
    margin-top: -14px;
    float: right;
    display: inline-block;
}
div.menuUserAcc, div.menuQuick_order, div.menufavourites, div.menulogout, div.menulogin, div.menusignup {
    display: inline-block;
}
.subscribe-wrapper .mbss-text.footerelementwrapper:nth-child(2) {
    width: 430px;
}
.footer :nth-child(5) {
    top: -3px;
    left: 9px;
}
/*.footer .ecs-column-4:not(.footer .ecs-column-4:nth-child(1)) {
    width: 15%;
    margin-right: 1%;
}
.footer .ecs-column-4:not(.footer .ecs-column-4:nth-child(1)):not(.footer .ecs-column-4:nth-child(6)) {
    left: 35px;
}*/
.footer.ecs-row .flex-child.ecs-column-4 {
    width: 15.3%;
}
.footer.ecs-row .ecs-column-4:first-of-type {
    width: 30.84%;
    margin-right: 3.35%;
}
.featured-products .flex-col .product-match-height.productwrapper.pf-product-list-item {
    background-color: #fff;
}
.featured-products .flex-col .product-match-height.productwrapper.pf-product-list-item div {
    color: #000;
}
a.UserAcc.navlink.top.w-clearfix.w-nav-link {
    left: -415px;
}

a.Quick_order.navlink.top.w-clearfix.w-nav-link {
    left: -113px;
}

a.favourites.navlink.top.w-clearfix.w-nav-link {
    left: 162px;
}

a.logout.navlink.top.w-clearfix.w-nav-link {
    left: 401px;
}

.midnav nav.nav-menu div#search-form {
    position: absolute !important;
    top: 25px;
}

i.freedelivery {
    height: 200px;
    display: inline;
}

.lines {
    display: inline;
    border-right: 1px solid #bfbfbf;
    width: 1px;
    padding: 15px 20px 19px;
    top: 10px;
}

i.basket.navlink.top.w-clearfix.w-nav-link {
    left: 15px;
    top: -32px;
}
.pf-multi-level-heading.w-dropdown-toggle.nav-link.nav-dropdown {
    padding: 9px 0px 9px 0px;
    min-width: 288px;
}
.footer.ecs-row .contactus-footer ul#redactor-toolbar-34 {
    left: -133px !important;
}
.topSectionNav {
    background-color: #92a8b4;
    height: 45px;
    width: 100%;
    color: #000;
}
.topSectionNav a.createAcc img, .topSectionNav a.login img {
    width: 147px !important;
    height: 18px !important;
}
.ecs-row-full-width .w-slider.slider .w-slider-nav {
    display: inline;
}
.topSectionNav .telNo {
    padding-top: 10px;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
}
.topSectionNav .telNo a{
    text-decoration:none;
    color:#000;
}
.topSectionNav .telNo a:hover{
    color:#fff;
}
.mid-footer .footer .logodescription {
    padding-left: 51px;
    width: 323px;
}
.mid-footer .footer .information-footer {
    padding-left: 59px;
}
.mid-footer .footer .ourcompany-footer {
    left: -63px;
}
.mid-footer .footer .myaccount-footer {
    left: 798px;
    top: -213px;
}
.mid-footer .footer .contactus-footer {
    left: 654px;
    top: -213px;
}
.mid-footer .mbss-text .mbss-content p a, .mbss-text div.mbss-content p a {
    color: hsla(0,0%,100%,.6) !important;
}
.mid-footer .mbss-text .mbss-content p a:hover, .mbss-text div.mbss-content p a:hover {
        color: #666!important;
}
.navlink.top:hover {
    color: #fff;
}
.topnav {
    width: 100%;
    margin-left: 0;
    padding-right: 15px;
    padding-left: 15px;
    border-bottom: 1px solid hsla(0,0%,100%,.1);
    background-color: #212121;
    text-align: center;
}
.navlink.top {
    display: inline-block;
    margin-left: 0;
    color: #000 !important;
    font-size: 14px;
    text-transform: none;
    font-weight: 300 !important;
}
#pf-currency-selector {
    display: inline-block;
    margin-left: 0;
    padding: 46px 10px;
    color: #92a8b4 !important;
    font-size: 16px;
    text-transform: none;
    height: 100%;
    font-weight: 300 !important;
}

    .navlink.top.w-nav-link:hover,
    .navlink.top.w-nav-link a:hover,
    #pf-currency-selector:hover {
        color: #fff !important;
        cursor: pointer;
    }

    #pf-currency-selector option {
        color: #000;
        background-color: #fff;
    }

        #pf-currency-selector option:hover {
            color: #000;
            background-color: #fff;
        }

#pf-currency-selector {
    margin-bottom: 0;
    line-height: 21px;
}

#bag-opener {
    vertical-align: top;
}

.home-featured-products-tabs .title-wrapper {
    display: none;
}

.featured-products {
    padding-top: 30px;
}

.featured-products .flex-col:last-child, .featured-products .flex-col:nth-child(3n+4) {
    margin-right: 1%;
}

.featured-products .flex-col:last-child, .featured-products .flex-col:nth-child(4n+4) {
    margin-right: unset;
}

.featured-products .flex-col {
    width: 24%;
    padding: 5px;
}

#pf-product-listing .pf-product-list-item, .featured-products-wrapper .pf-product-list-item {
    margin-bottom: 5px;
}

.pf-product-list-item {
    padding: initial;
}

.pf-product-list-item, .pf-recent-related-product-item a {
    margin-top: 0;
    margin-bottom: 0;
}

/* Overwrite stupid rules in platform-style.css */
#pf-product-listing .flex-col:nth-of-type(3n+1) {
    margin-left: 0.5%;
}

#pf-product-listing .flex-col:last-child,
#pf-product-listing .flex-col:nth-child(2n+1),
#pf-product-listing .flex-col:nth-of-type(3n),
#pf-product-listing .flex-col:nth-child(3n+3) {
    margin-right: 0.5%;
}

.pf-filter-dropdown-list {
    background-color: transparent;
}

.add-to-wishlist-btn {
    color: hsla(0,0%,100%,.6) !important;
    text-decoration: underline !important;
}

.pf-btn.pf-btn-secondary {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #000 !important;
    margin-right: 7px;
    -webkit-transition: color .5s ease,background-color .5s ease !important;
    font-size: 15px;
    line-height: 36px;
    font-weight: 400;
    text-shadow: none;
    margin-bottom: 5px;
    text-transform: uppercase;
}

    .pf-btn.pf-btn-secondary:hover {
        background-color: transparent !important;
        color: white !important;
        border-color: white !important;
    }

.whiteMessage.custom-stock-message {
    color: #fff;
}

.new-left-toplist {
    top: 8px !important;
    left: 8px !important;
}

.new-right-toplist {
    top: 8px !important;
    right: 8px !important;
}

.new-left-bottomlist {
    top: 200px !important;
    left: 8px !important;
}

.new-right-bottomlist {
    top: 200px !important;
    right: 8px !important;
}

.new-left-bottomlist, .new-right-bottomlist, .new-right-toplist, .new-left-toplist {
    background-color: #92a8b4;
}

.pf-new-product-tag {
    top: 200px;
    content-visibility: auto;
}

.pf-new-product-tag.discon::before {
    font-family: "Font Awesome 5 Pro";
    content: '\f1b8';
    font-size: 28px;
}

.exclusive-big {
    position: relative !important;
    top: -7px !important;
}

.exclusive-small {
    position: relative !important;
    top: -25px;
    display: block;
    font-size: 8px !important;
    line-height: 9px;
    font-weight: 700;
}

.pf-sale-product-tag {
    top: 200px;
    left: 7px;
}

.nav-link.mid {
    border: none;
    color: #92a8b4;
    text-transform: none;
    padding: 32px 0 20px 10px;
    float: right;
    font-weight: 300;
}

.about-us {
    padding-top: 0;
}

.w--current {
    color: #92a8b4 !important;
}


.input.inline {
    display: inline-block;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #898989;
    border-radius: 0;
    -webkit-transition: opacity .5s ease,background-color .5s ease,color .5s ease,border .5s ease;
    transition: opacity .5s ease,background-color .5s ease,color .5s ease,border .5s ease;
}

.input, .input.inline {
    background-color: transparent;
}

    .fontello, .input.inline, .sub, h3 {
        font-style: normal;
    }

.input,.pf-input {
    height: 48px;
    margin-bottom: 10px;
    padding: 0 12px;
    border: 1px solid #898989;
    -webkit-transition: border .5s ease;
    transition: border .5s ease;
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}
#prod-search-tbx {
    color:#fff;
}

#checkout select.input {
    appearance: auto;
    -moz-appearance: auto;
    -webkit-appearance: auto;
}

.pf-btn {
    height: unset;
}

    .pf-btn.pf-btn-primary, a#discount-btn {
        padding: 12px 22px;
        border: 1px solid #000 !important;
        background-color: #323232 !important;
        transition: color .5s ease,background-color .5s ease !important;
        color: #fff !important;
        border-radius: 6px;
    }

    .pf-btn-sm.pf-btn-default, .pf-btn.pf-btn-primary, a#discount-btn {
        -webkit-transition: color .5s ease,background-color .5s ease !important;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        text-shadow: none;
        text-decoration: none;
        margin-bottom: 5px;
        text-transform: uppercase;
        border-radius: 6px;
    }

#pf-popup-cart-wrapper .pull-right.pf-btn.pf-btn-sm.pf-btn-primary.pf-request-check-stock.form-displayed, .pf-request-check-stock.form-displayed {
    background-color: #92a8b4 !important;
    border-color: #92a8b4 !important;
    color: #fff;
}

.ecs-row-container.cart-buttons-popup {
    padding-top: 10px;
}

#top-cart-below {
        padding: 0;
}

a.pf-reset-pass {
    margin-bottom: 5px;
    border: 1px solid #fff;
    -webkit-transition: color .5s ease,background-color .5s ease !important;
    transition: color .5s ease,background-color .5s ease !important;
    color: #fff;
    text-decoration: none !important;
    text-transform: uppercase;
    border-radius: unset;
    padding: 12px 22px !important;
}

a.pf-normal-link {
    transition: color .5s ease;
    color:#000;
    text-decoration: underline;
}
a.pf-normal-link:hover {
    color: #92a8b4;
}
#pf-cart-wrapper .pf-checkout-cart-table a.pf-normal-link {
    color: #000;
}
.subscribe-wrapper input#signup-btn {
    background-color: #363636;
    border-color: #363636;
    height:45px;
    font-size:20px;
    line-height:12px;
    margin:0;
    width:100%;
    max-width:250px;
    top:4px;
    position:relative;
}
.pf-checkout-hr, a.pf-reset-pass {
    background-color: transparent;
}
.filter-subdropdown-list .filter-subdropdown-link {
    padding: 3px 0 3px 25px;
}
#pf-clear-filters-btn {
    display: inline-block;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    text-shadow: none;
    text-transform:uppercase;
    text-align:start;
    padding-left:25px;
    padding-top:15px;
    padding-bottom:15px;
}

.about-us .section:nth-child(2n+1) {
    background-color: #fff;
}

.whiteMessage.custom-stock-message {
    color: #fff;
}

.pf-product-list-item .pf-product-price {
    color: #92a8b4;
    font-size: 16px;
    text-align: center !important;
}

.product-price {
    margin-bottom: 10px;
    color: #92a8b4;
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
    font-weight: 300;
    text-align: left;
}

.pf-slide-item .pf-product-price {
    margin-bottom: 10px;
}

.qty-tbx {
    width: 40px;
}

.qty-tbx {
    display: inline-block;
    border: 1px solid #898989;
    padding: 0 10px;
    background-color: #fff;
    border-radius: 0;
    height: 40px;
    margin-bottom: 4px;
    font-weight: 500;
    color: #000;
}

.pf-qty-btn.pf-qty-btn-plus {
    border-right: 1px solid #666;
}

.pf-qty-btn {
    border: 1px solid #fff;
}

.pf-qty-btn {
    color: #666;
    height: 40px;
}

.pf-product-list-item .pf-btn {
    height: 40px;
}

.pf-product-list-item .pf-add-cart-wrapper {
    margin-bottom: 15px;
}

.pf-btn.pf-btn-primary.pf-btn-with-qty {
    border-left: none !important;
    background-color: transparent;
    margin-right: 0;
}

#filter .cat-selected {
    font-weight: 700;
    color: hsla(0,0%,100%,.6) !important;
}

.filter-cats:not(.filter-subdropdown-list) {
    padding-top: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}

.pf-filter-item {
    font-size: inherit;
    width: 100%;
    border-bottom: none;
    padding: 3px 10px 0 20px;
}

    .pf-filter-item a:hover {
        color: #fff;
    }

.filter-dropdown {
    background-color: transparent;
}

.pf-filter-dropdown-list .pf-filter-dropdown-btn {
    margin: 2px 20px auto auto;
}

.filter-dropdown-list, .filter-subdropdown-list, .filter-subdropdown-list.w--open {
    background-color: transparent;
    position: relative;
}

.pf-list-view-paging {
    float: left;
}

.pagination {
    position: relative;
    margin-top: 0;
    margin-bottom: 30px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: left;
}

.pagination, .textarea {
    background-color: transparent;
}

.pf-paging-btn {
    border: unset;
}

.pageno {
    padding: 8px;
    margin-right: 0;
    -webkit-transition: color .5s ease,background-color .5s ease;
    transition: color .5s ease,background-color .5s ease;
    color: hsla(0,0%,100%,.6);
    line-height: 30px;
    font-weight: 400;
    text-align: center;
}

.m-filter-wrapper {
    display: block;
    opacity: 1;
    color: hsla(0,0%,100%,.6);
    font-weight: 400;
    border: 1px solid #898989;
}

.m-filter-wrapper, .pageno {
    background-color: transparent;
}

.custom-stock-message, .errormessage, .pf-custom-stock-message {
    color: rgba(255,34,34,.7);
}

.pf-additional-list-info {
    color: #999;
}
.pf-sale-cart-tag .pf-btn.pf-view-cart {
    height: 34px;
    padding: 6px 16px;
}

.textarea {
    min-height: 215px;
    margin-bottom: 15px;
    border: 1px solid #898989;
    -webkit-transition: border .5s ease;
    transition: border .5s ease;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
}

.pagination, .textarea {
    background-color: transparent;
}

#contact-us-email-form {
    margin-top: 20px;
}

.contact-input {
    height: 48px;
    margin-bottom: 10px;
    padding: 0 12px;
    border: 1px solid #898989;
    -webkit-transition: border .5s ease;
    transition: border .5s ease;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

    .contact-input, .contact-input.inline {
        background-color: transparent;
    }

.contact-link {
    color: #92a8b4;
}

.area-col, .contact-link, .img-overlay {
    display: block;
}

.megalist.w--open {
    border: none !important;
    padding: 10px 10px 30px !important;
    border-top-color: #eee !important;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.33) !important;
}

.heading {
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    color: #141414;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 1px;
}

.megaheading, .nav-container .heading {
    color: #141414 !important;
    text-transform: uppercase;
    padding-left: 0 !important;
    border: none !important;
}

.dropdownlink {
    padding-top: 6px;
    padding-bottom: 6px;
    transition: color .5s ease,background-color .5s ease;
    color: hsla(0,0%,8%,.6);
    font-weight: 400;
    text-transform: none;
}

.dropdownlink, .navlink.dropdown {
    -webkit-transition: color .5s ease,background-color .5s ease;
}

    .dropdownlink.w--current, .dropdownlink:hover {
        color: #141414;
        text-decoration: underline;
    }

#filter .subdropdown-toggle {
    display: inline-block;
}

.filter-cats .subdropdown-toggle {
    padding-top: 0;
}

.about-us .section:nth-child(odd) > div > div > div.repeatableText {
    float: left;
    width: 47.25%;
}

.about-us .section:nth-child(even) > div > div > div.repeatableText {
    float: right;
    width: 47.25%;
}

.about-us .section:nth-child(odd) > div > div > div.mbss-listItem2-image-wrapper {
    float: right;
    width: 47.25%;
    padding-left: 40px;
}

.about-us .section:nth-child(even) > div > div > div.mbss-listItem2-image-wrapper {
    float: left;
    width: 47.25%;
}

.hide-mobile {
    display: none;
}

.cat-selected {
    color: #666 !important;
}

.pf-filter-item a {
    transition: color .5s ease;
    color: #fff;
}

    .pf-filter-item a, .pf-filter-item a.cat-selected {
        text-decoration: none !important;
    }

.pf-filter-dropdown-btn {
    opacity: unset !important;
    color: #fff;
    margin-left: 10px;
}

.pf-filter-item a, .pf-filter-item a.cat-selected {
    text-decoration: none !important;
}

#checkout-cart, .pf-checkout-cart-table td {
    border: 1px solid #444;
}

#checkout-cart #purchaserNote {
    min-height: 60px!important;
}

.table.cart-table {
    width: 100%;
    text-align: center;
}

.cart-table, .video-btn, td.price {
    text-align: center;
}

th {
    font-size: 1px 10px;
    line-height: 23px;
    font-weight: 400;
    padding: 7px;
}

#top-cart-holder {
    margin: 0;
    padding: 15px;
}

    #top-cart-holder, #top-cart-holder a:hover {
        color: #fff;
    }

.clear-btn, .green-btn, .product-tab, .table-title, th {
    text-transform: uppercase;
}

.banner-extra-large, .exclusive-big, .recycle-big, a#discount-btn, th {
    letter-spacing: 1px;
}

#top-cart-holder, #top-cart-holder a:hover, #top-cart-holder a {
    color: #000;
}

#bag-opener .top-nav-links, #bag-opener i {
    color: #fff;
}

#bag-opener .top-nav-links:hover, #bag-opener i:hover {
     color: #92a8b4;
}

#bag-closer {
    float: right;
}

.pf-terms-wrapper {
    width: 47.5%;
}

.pf-checkout-actions .pf-terms-wrapper {
    /* width: 50%;*/
    float: right;
}

.pf-disc-table td, .pf-spec-table td, .pf-terms-wrapper, .pf-terms-wrapper .checkbox-label, .pf-terms-wrapper a:hover, .pf-terms-wrapper p {
    color: #000;
}

.brand.w-nav-brand, a#discount-btn {
    position: relative;
}

#pf-discount-input-wrapper {
    width: 50%;
    min-width: 300px;
}

#checkout .row, .shopppingrow {
    border-bottom: 1px solid hsla(0,0%,100%,.2);
}

.pf-terms-wrapper a {
    color: #333 !important;
}

li {
    padding-top: 7px;
    padding-bottom: 7px;
}
#myCarousel-1-1 .slider-container .w-container {
    color:#fff!important;
}
.slider-container {
    background-image: none;
    opacity: 1;
    transition: opacity 2000ms ease-out 0s;
    top: 6%;
    text-align: start;
}

.slide, .slide.slide-2 {
    background-position: 0 0,50% 50% !important;
    background-size: auto,cover !important;
    background-repeat: repeat,no-repeat !important;
}

.banner-text-small {
    margin-bottom: 5px;
    font-family: Lato,sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
}

.banner-text-medium {
    font-size: 24px;
    line-height: 29px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.8);
    text-transform:uppercase;
}

.banner-text-large {
    margin-top: 0;
    text-transform: uppercase;
}

.w-slider-mask {
    display: block;
    z-index: 1;
    left: 0;
    right: 0;
    white-space: nowrap;
}

.pf-slider-button.pf-slider-button-prev {
    z-index: 3;
    opacity: 0.5;
    left: 30px;
}

.pf-slider-button.pf-slider-button-next {
    z-index: 4;
    opacity: 0.5;
    right: 30px;
}

.w-slide, .w-slider-mask {
    position: relative;
    height: 100%;
}

.navlink.dropdown {
    padding-right: 30px;
    transition: color .5s ease,background-color .5s ease;
}

    .navlink.dropdown.w--open {
        color: #92a8b4;
    }


.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px 15px 27px;
    border: 1px solid #fff;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff !important;
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    text-shadow: none;
    border-radius: 3px;
}

.mbss-banner-text .mbss-content a:hover {
    border-color: transparent !important;
    background-color: #92a8b4 !important;
    color: #fff;
    text-decoration: none;
}

.mbss-banner-text .mbss-content a {
    margin: 20px 7px 10px;
    border: 1px solid #fff;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease;
    transition: color .5s ease,background-color .5s ease;
    text-shadow: 1px 1px 6px rgba(0,0,0,.4);
    cursor: pointer;
}

.button-wrapper a:not(.re-icon):not(.mbss-btn-icon):not(.re-button), .button-wrapper input, .mbss-banner-text .mbss-content a {
    padding: 14px 25px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    color: #fff !important;
}

.w-slider-nav {
    display: none;
}

.tab {
    transition: all .5s ease-in-out;
    background-color: transparent;
    color: #92a8b4;
    text-decoration: none;
    border: 1px solid #92a8b4;
}

.tab, .w-form.qtywrapper {
    display: inline-block;
    text-align: center;
}

.tab, .video-btn {
    -webkit-transition: all .5s ease-in-out;
}

.tab {
    margin-right: 10px;
    padding: 4px 20px;
    font-size: 14px;
    line-height: 20px;
}
#pf-my-account-page .tab {
    margin-right: 10px;
    padding: 13px 20px;
    font-size: 14px;
    line-height: 20px;
}

.product-code, .tab {
    font-weight: 400;
    text-transform: uppercase;
}

.tab-menu {
    text-align: center;
}

.tab:hover {
    color: #fff;
    border-color: #92a8b4;
}

.tab.w--current, .tab:hover {
    background-color: #92a8b4;
    text-decoration: none;
    color: #fff !important;
}

.button-wrapper a:hover {
    background-color: #92a8b4 !important;
    border-color: transparent !important;
    -webkit-transition: color .5s ease,background-color .5s ease !important;
    transition: color .5s ease,background-color .5s ease !important;
}

.featured-products-wrapper .pf-custom-stock-message {
    display: none !important;
}
.pf-product-list-item .pf-btn.pf-btn-primary.login-modal-btn {
    color: #fff;
    background-color: #000;
    border: 1px solid #000!important;
    border-radius: 6px;
}
.pf-product-list-item .pf-btn.pf-btn-primary.login-modal-btn:hover {
    color: #000!important;
    background-color: #fff!important;
}

#TradeSignUpUseBillingAddressLabel {
    margin-bottom: 15px;
}

.section.title-wrapper {
    margin-bottom: 0;
}

.section.top-categories {
    display: none;
}

.section.w-tabs {
    margin: 0 0 35px 0;
    padding: 0;
}

.section-bg {
    background-image: -webkit-linear-gradient(270deg,rgba(33,33,33,.7),rgba(33,33,33,.7)),url(../images/wavecut.png);
    background-image: linear-gradient(180deg,rgba(33,33,33,.7),rgba(33,33,33,.7)),url(../images/wavecut.png);
    background-position: 0 0,0 0;
    font-weight: 300;
    background-color: transparent;
}

.no-space {
    margin: 0;
    padding: 0;
}

.container {
    text-shadow: 1px 1px 8px rgba(0,0,0,.1);
}

.fixed-page-text-zone h3 {
    color: #323232;
    font-size: 24px;
}

#signupForm .ecs-row h3 {
    color: #323232;
}

#signupForm select.w-select.input {
    background-repeat: no-repeat;
}

.ecs-main-container {
    padding-top: 20px;
}

#pf-address-auto-wrapper {
    display: none;
}

#search-form-mobile {
    display: none !important;
}

.phone-link {
    float: right;
}

.phone-header {
    display: inline-block;
}

    .phone-header a {
        text-decoration: none;
    }

#bag-opener.nav-link.mid {
    font-size: 16px;
    line-height: 21px;
}

.header-link {
    color: #92a8b4;
}

.center-flex {
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
    height: min-content;
    padding-bottom: 20px;
}

.ecs-column-2-full {
    width: 50%;
    overflow: hidden;
}
.slider {
    height: 600px;
}
.pf-recent-viewed-section, .pf-related-viewed-section {
    border-top: none;
}

#pf-product-actions-wrapper {
    border: none;
    padding-left: 0;
    padding-top: 0;
}

#pf-related-slider .pf-product-code {
    display: none;
}

.pf-recent-list-wrapper, .pf-related-list-wrapper {
    margin-left: 0;
    margin-right: 0;
}

.pf-slide-item {
    border: none;
    background-color: transparent;
}

    .pf-slide-item .pf-product-name {
        margin-top: 0;
        margin-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        color: #fff;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
    }

.product-detail-bottom-tabs-section .tab-menu {
    display: flex;
    justify-content: start;
}

#pf-product-details-page .pf-product-image-thumb.w--current {
    border: none;
}

#pf-product-details-page .pf-product-image-thumb:nth-child(3n) {
    margin-left: 1%;
}

.pf-product-details-info-wrapper .pf-product-code {
    font-size: 18px;
    margin-bottom: 7px;
    font-family: Lato,sans-serif;
    color: #92a8b4;
    line-height: 18px;
    font-weight: 400;
    text-transform: uppercase;
}

.product-detail-description.p {
    color: #363636;
}

#pf-product-details-page .pf-detail-view-add-cart-wrapper {
    margin-bottom: 5px;
}

.mega-list.w--open {
    border: none;
    z-index: 1;
}

.dropdownlink:hover {
    text-decoration: underline !important;
    color: #000 !important;
}

.w-nav-link.navlink:hover {
    color: #92a8b4 !important;
}

.nav-link.nav-dropdown {
    padding-right: 30px;
    color: hsla(0,0%,100%,.7);
    font-size: 14px;
    padding-top: 0;
}

    .nav-link.nav-dropdown:hover {
        color: #92a8b4 !important;
    }

.ecs-main-container {
    float: none;
}

.pf-product-list-options {
    padding-top: 10px;
}

.ecs-row.filter-select-wrapper {
    margin-top: 10px;
    margin-bottom: 20px;
}

.pf-btn.pf-btn-primary:hover, .pf-load-more-btn:hover, .pf-btn.pf-btn-sm.pf-btn-primary.post-new-wishlist-btn, #bag-opener-notification, a#discount-btn:hover {
    background-color: #92a8b4 !important;
    color: #fff !important;
    text-decoration: none !important;
    border-color: transparent !important;
    cursor: pointer !important;
}

#login-modal {
    background-color: #fff;
    border: 1px solid #898989;
    font-weight: 300;
    color: #000;
}

.pf-recent-related-header {
    font-family: 'Playfair Display',sans-serif;
    font-weight: 400;
    font-size: 28px;
}

.pf-btn.pf-add-to-cart-btn {
    height: 48px;
}

#billing .note-text, .trade-price-label, a.hidden {
    display: none;
}

.product-detail-price {
    display: block;
    margin-top: 27px;
    margin-bottom: 10px;
    color: #92a8b4;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    color: #fff!important;
}

.errormessage-block {
    padding: 5px 10px;
    margin-bottom: 15px;
    background-color: rgba(160,0,0,.8);
    color: #fff;
    text-align: left;
}

td.cart-description {
    text-align: left;
}

    td.cart-description .pf-normal-link:hover {
        text-decoration: none !important;
    }

.about-us .section:last-child {
    padding-bottom: 0 !important;
}

.pf-page-wrapper .pf-tab-header.w--current {
    color: #fff;
    border-color: #92a8b4;
    background-color: #92a8b4;
    text-decoration: none;
    border-radius: unset;
}

.w-tab-menu .pf-tab-header.w--current {
    background-color: #92a8b4;
    color: #fff !important;
    position: relative;
    top: 1px;
    border: 1px solid #92a8b4;
    border-bottom: none;
}

.w-tab-menu .pf-tab-header {
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #92a8b4;
    color: #92a8b4;
}

.pf-colour-filter-dropdown-btn.closed, .pf-filter-dropdown-btn.closed, .pf-spec-filter-dropdown-btn.closed {
    position: absolute;
    margin-left: 10px;
}

.pf-all-wishlists .filter-dropdown {
    background-color: transparent;
}

.pf-btn.pf-btn-colour-one {
    background-color: #444;
    border-color: #92a8b4 !important;
    color: #fff;
}

.pf-input-with-btn-wrapper .pf-btn.pf-tbx-btn, .pf-quick-order-top .pf-btn.pf-btn-sm {
    height: 34px;
    padding: 5px 9px;
}

#pfQoSearchBtn.pf-btn-colour-one.pf-btn-with-icon {
    border: 1px solid transparent;
}

    #pfQoSearchBtn.pf-btn-colour-one.pf-btn-with-icon:hover {
        background-color: #92a8b4 !important;
        border-color: transparent !important;
        color: #fff !important;
    }

#pfQoSearchBtn .fa-search {
    top: 3px;
}

#pfQoSearchResults .pf-qty-wrapper.pf-quick-order-qty-wrapper .pf-qty-btn-minus {
    height: 34px;
    border-left: 1px solid #666;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}

#pfQoSearchResults .pf-qty-wrapper.pf-quick-order-qty-wrapper .pf-qty-btn-plus {
    height: 34px;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}

#pfQoSearchResults .pf-add-cart-row .pf-add-to-cart-btn {
    border: 1px solid #444 !important;
}

    #pfQoSearchResults .pf-add-cart-row .pf-add-to-cart-btn:hover {
        border: 1px solid #92a8b4 !important;
    }

.pf-btn.pf-btn-colour-one:hover {
    background-color: #92a8b4 !important;
    border: solid 1px #92a8b4 !important;
    color: #fff !important;
}

.pf-quick-order-quick-entry .pf-qty-btn.pf-qty-btn-minus {
    height: 34px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.pf-quick-order-quick-entry .pf-qty-btn.pf-qty-btn-plus {
    height: 34px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.wishListName {
    color: #fff;
}

.new-wishlist-btn {
    margin-left: 10px;
}

.pf-input.pf-hover-colour-one, .pf-input.pf-hover-colour-one {
    background-color: transparent;
}

.pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover {
    border-color: #fff;
    background-color: transparent;
}

a.heading.w-dropdown-link:hover {
    text-decoration: underline;
}

.ui-menu-item.ui-state-focus {
    color: #000 !important;
}

.pf-btn.pf-btn-primary.add-to-favorites-btn {
    border: 1px solid #ccc !important;
    color: #fff !important;
    padding: 0 5px;
    height: 34px;
}

.pf-btn.pf-btn-primary.add-to-favorites-btn:hover,
.pf-btn.pf-btn-sm.pf-btn-default.create-wish-List-btn:hover {
    color: #fff !important;
    background-color: #92a8b4 !important;
    border-color: transparent !important;
}

.pf-favs-table .remove-from-this-wishlist-btn {
    color: hsla(0,0%,100%,.6);
}

    .pf-favs-table .remove-from-this-wishlist-btn:hover {
        color: #fff;
    }

.pf-wishlist-quick-entry-code {
    color: hsla(0,0%,100%,.6);
}

.ecom-filter-group {
    padding-top: 10px;
}

#pf-my-account-page .pf-btn-sm.pf-btn-default {
    padding: 12px 22px;
    border: 1px solid #92a8b4 !important;
    background-color: #fff !important;
    color: #92a8b4 !important;
    border-radius: 6px
}

    #pf-my-account-page .pf-btn-sm.pf-btn-default:hover {
        background-color: #92a8b4 !important;
        color: #fff !important;
        text-decoration: none !important;
        border-color: transparent !important;
        cursor: pointer !important;
    }

.pf-my-orders-table .pf-btn-primary.view-order-details-btn,
.pf-my-orders-table .pf-btn-primary.hide-order-details-btn,
.pf-my-orders-table .pf-btn-primary.reorder-order-btn,
.pf-my-orders-table .pf-btn-primary.return-order-btn,
.pf-my-returns-table .pf-btn-primary.view-return-details-btn,
.pf-my-returns-table .pf-btn-primary.hide-return-details-btn {
    color: #92a8b4 !important;
    background-color: transparent !important;
    text-transform: none !important;
}

    .pf-my-orders-table .pf-btn-primary.view-order-details-btn:hover,
    .pf-my-orders-table .pf-btn-primary.hide-order-details-btn:hover,
    .pf-my-orders-table .pf-btn-primary.reorder-order-btn:hover,
    .pf-my-orders-table .pf-btn-primary.return-order-btn:hover,
    .pf-my-returns-table .pf-btn-primary.view-return-details-btn:hover,
    .pf-my-returns-table .pf-btn-primary.hide-return-details-btn:hover {
        color: #363636 !important;
    }

.pf-order-item-actions .pf-btn-primary.reorder-item-btn {
    color: #333 !important;
    background-color: transparent !important;
    text-transform: none !important;
}

.pf-my-orders-table .pf-btn-primary.reorder-item-btn:hover {
    color: #333 !important;
}

.pf-order-details, .pf-return-details {
    color: #333;
}
.section.brandsection .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    color: #333 !important;
    background-color: #fff !important;
}

.legal-template a {
    color: #fff;
}

ul.mbss-textArea-btns {
    top: 0 !important;
}

.about-us ul.mbss-textArea-btns {
    top: 40px !important;
}

.pf-image-collection a:not(.mbss-image-x-link) {
    position: relative;
}

.pf-image-collection h2 a {
    text-decoration: none;
    color: #fff;
}

.heading, .webui-popover {
    white-space: normal;
}

#pfQoSearchBtn {
    border: none;
}

    #pfQoSearchBtn.pf-btn.pf-btn-colour-one:hover {
        background-color: #92a8b4 !important;
        border-color: transparent !important;
    }

#pfQoSearchResults .pf-qty-btn-minus, #pfQoSearchResults .pf-qty-btn-plus {
    height: 34px !important;
    border: none;
}

#signupForm #pf-address-auto-wrapper {
    display: block;
}

#signupAddressPostcodeLookup .postcode-lookup-btn {
    height: 48px;
}

#deliveryAddressPostcodeLookup .postcode-lookup-btn {
    height: 48px;
}

.delivery-calendar-picker {
    color: #fff;
    border: 1px solid #f1f1f1;
}

.lg-outer .lg-video .lg-video-object.lg-object {
    position: absolute !important;
}

.navlink.top:hover {
    color: #fff !important;
}

.pf-quick-add-to-cart-btn.pf-btn-with-qty {
    background-color: #666 !important;
    border-color: transparent !important;
}

    .pf-quick-add-to-cart-btn.pf-btn-with-qty:hover {
        background-color: #92a8b4 !important;
        border-color: transparent !important;
    }

.pf-quick-order-results-wrapper .pf-add-to-cart-btn,
.pf-quick-order-results-wrapper .pf-qty-btn {
    border: 1px solid #92a8b4 !important;
    height: 34px;
    color: #000 !important;
}
.pf-quick-order-results-wrapper .pf-add-to-cart-btn {
    color: #fff !important;
}

.pf-image-collection.section-bg .center-flex h2 {
    line-height: 42px;
}

#topUpBalanceBtn {
    top: -3px;
    height: 48px;
    line-height: 22px;
}

.pf-checkout-actions .ecs-row-1-2 #makePaymentBtn {
    max-width: 50%;
    float: right;
    margin: 20px auto 0;
}

.pf-checkout-actions .ecs-row-1-2 #makeConfirmedPaymentBtn {
    max-width: 50%;
    margin-top: 20px;
    margin-bottom: 30px;
    float:right;
}

.pf-checkout-actions .ecs-row-1-2 {
    width: 100% !important;
}

.pf-order-return-table select {
    color: #000;
    margin-bottom: 0px;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
}

.pf-submit-return-btn.pf-btn.pf-btn-sm.pf-btn-primary {
    border-radius: 0;
    background-color: #92a8b4 !important;
    color: #fff;
}

.pf-my-returns-table .pf-btn.pf-btn-primary.save-return-notes-btn {
    border: 1px solid #92a8b4 !important;
    color: #92a8b4 !important;
}

    .pf-my-returns-table .pf-btn.pf-btn-primary.save-return-notes-btn:hover {
        border: 1px solid #fff !important;
        color: #fff !important;
    }

.pf-terms-wrapper .checkbox-label {
    font-weight: 300;
}

.placeItem {
    background-color: #92a8b4;
}

#pf-search-place-radius {
    width: 75%;
    display: inline-block;
}

#pf-search-button {
    top: -3px;
}

#infoWindow, #infoWindow a {
    color: #000;
}
.pf-favs-wrapper input[type=text].pf-create-wish-list {
    color: #fff;
    padding: 14px;
    border: 1px solid #fff;
    line-height: 18px;
    height:48px;
}

/* Override platform-style default shrink behaviour */
.shrink .brand {
    padding-top: 15px;
    padding-bottom: 10px;
}

.shrink .searchform-wrapper {
    margin: 15px auto 0 20px;
}

.searchform-wrapper i {
    font-size: 20px;
    top: -33px;
    right: 13px;
}

.mega-list .w-dropdown-link {
    white-space: pre-wrap;
}

input.pf-multi-select-item + label::after {
    border: 1px solid #92a8b4;
    color: #92a8b4;
}

input.pf-multi-select-item:checked + label::after {
    background-color: #92a8b4;
}


.pf-product-list-bottom {
    background-color: rgba(54,54,54,0.95);
}

.pf-multi-selected-product {
    position: relative;
    border-left: 1px solid #898989;
}

    .pf-multi-selected-product:last-child {
        border-right: 1px solid #898989;
    }

.pf-multi-select-remove-item {
    float: none;
    position: absolute;
    right: 0;
    padding: 5px;
    top: 0;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #363636;
    border: 1px solid #fff;
    color: #fff;
    transition: background-color .5s ease;
}

    .pf-multi-select-remove-item:hover {
        color: #fff;
        background-color: #92a8b4;
        border: 1px solid #92a8b4;
    }

.pf-disc-table tbody tr:nth-child(2n+1),
.pf-spec-table tbody tr:nth-child(2n+1) {
    background-color: #303030;
}

.pf-disc-table td,
.pf-disc-table th,
.pf-spec-table td,
.pf-spec-table th {
    border: 1px solid #898989;
    color: #fff;
}

/***************** Download Page *****************/
.downloads-widget {
    position: relative;
}

.downloads-dropdown {
    height: auto;
}

.downloads-widget .w-icon-dropdown-toggle {
    bottom: 10px !important;
}

.downloads-dropdown .w-dropdown-list.w--open {
    border-bottom: none;
    border-top: 1px solid #989c9f !important;
    margin-bottom: 0px;
    display: table;
}

.downloads-dropdown-list {
    border-color: #eee;
}

.downloads-txt {
    margin-left: 20px;
}

#search-div-block-compact > .downloads-link {
    vertical-align: 4px;
    color: #000;
    text-align: center;
}

.downloads-wrapper .addListItem2Btn {
    bottom: -50px;
}

.downloads-wrapper .downloads-dropdown-list .addListItem2Btn {
    bottom: -31px;
    right: 80px;
}

.ecs-main-container-nbp .mbss-listItem2-image, .ecs-main-container-nbp .image-placeholder {
    min-width: 130px;
}

mbss-listItem2-wrapper .w-container w-row .mbss-hover-highlight {
    width: 100%;
}

.downloads-dropdown {
    margin: 0;
    display: block;
}

    .downloads-dropdown p {
        margin-bottom: 5px;
    }

.downloads-dropdown {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #989c9f;
    color: #fff;
    text-align: left;
    text-decoration: none;
    padding-top: 5px !important;
}

.downloads-dropdown-list {
    border-bottom: 0;
}

    .downloads-dropdown-list.w--open {
        box-shadow: none;
        position: relative;
        height: auto;
        padding: 20px 10px 10px;
        background-color: transparent;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        color: #222;
        font-weight: 300;
        margin-top: 0px;
    }

.ecs-main-container-nbp .mbss-text,
.dropdown-faq.w-icon-dropdown-toggle,
.redactor-placeholder:after {
    color: #000 !important;
}

.downloads-dropdown {
    width: 100%;
    padding: 0;
    position: relative;
    vertical-align: top;
    color: #222;
    padding: 0;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    display: inline-block;
}

.downloads-div {
    display: flex;
    margin-bottom: 20px;
}

.dropdown-faq:before {
    bottom: 5px;
    content: '\002B';
    font-size: 24px;
    font-family: Lato,sans-serif, Georgia, Times, 'Times New Roman', serif;
    float: right;
}

.w--open .dropdown-faq:before {
    content: '\2212';
}

.downloads-wrapper .addListItem2Btn {
    bottom: -50px;
}

.downloads-wrapper .downloads-dropdown-list .addListItem2Btn {
    bottom: -31px;
    right: 80px;
}

.downloads-page-wrapper .mbss-listItem2-image, .downloads-page-wrapper .image-placeholder {
    min-width: 130px;
}

.question-link {
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
}

    .question-link .mbss-listItem-text p {
        padding-top: 0;
    }

.downloads-dropdown-list .mbss-listItem2-wrapper.w-container {
    max-width: unset;
}

.downloads-dropdown-list a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    color: #000 !important;
}
#pf-my-account-page .pf-add-to-cart-btn {
    height:40px;
    line-height:15px;
}
.mbss-text.question-link ul.mbss-textArea-btns {
    left: 469px;
    top: -67px !important;
}

.mbss-text.downloads-dropdown-list span.re-button-tooltip {
    top: 0 !important;
}

.mbss-text.downloads-dropdown-list ul.redactor-toolbar {
    top: -38px !important;
    left: -38px !important;
}

.redactor-toolbar {
    z-index: 1000 !important;
}

.mbss-text.downloads-dropdown-list ul.mbss-textArea-btns {
    width: 78px;
}

.downloads-dropdown-list a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    color: #ffffff;
}

.downloads-dropdown-list a:hover {
    color: #ffffff;
}

.downloads-dropdown-list .downloads-div .downloads-txt a:hover {
    color: #666 !important;
}

.downloads-dropdown-list .downloads-div .deleteListItem2Btn {
    visibility: visible !important;
}

#signupAddressPostcodeLookup .pf-btn-primary, #deliveryAddressPostcodeLookup .pf-btn-primary {
    margin-right: 0;
}
/************** Download Page END *****************/

#checkout {
    padding-bottom: 20px;
}

#validNotes {
    float: none;
    text-align: center;
}

.pf-load-more-btn {
    border: 1px solid #92a8b4 !important;
    color: #fff !important;
    background: #92a8b4 !important;
    cursor: pointer !important;
}

.pf-stock-info .pf-stock-summary-backorder, .pf-stock-info .pf-stock-summary-unavailable {
    color: rgba(255,34,34,.7);
}

#pf-product-details-page .pf-product-image-thumb.w--current {
    max-height: 230px;
}

.section-bg.pf-product-listing div#list-products-wrapper .pf-load-more-container .pf-load-more-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 25px;
    margin-bottom: 27px;
    width: 183px;
    height: 40px;
    background-color: #212121 !important;
    border: 1px solid #fff !important;
    letter-spacing: 1px;
}

    .section-bg.pf-product-listing div#list-products-wrapper .pf-load-more-container .pf-load-more-btn:hover {
        background-color: #92a8b4 !important;
        color: #fff !important;
        text-decoration: none !important;
        border-color: transparent !important;
        cursor: pointer !important;
        transition: all .5s ease-in-out;
    }

.remodal-is-opened #pf-popup-cart-wrapper .cart-buttons-popup .pf-continue-shopping-cart,
#pf-popup-cart-wrapper .pf-proceed-checkout-btn,
#pf-popup-cart-wrapper .pf-request-check-stock,
.request-check-stock-wrapper .pf-btn.pf-btn-primary {
    color: #fff;
    padding: 12px 10px;
    background-color: #000 !important;
    margin-right: 0 !important;
}

.remodal-is-opened #pf-popup-cart-wrapper .cart-buttons-popup .pf-continue-shopping-cart:hover,
#pf-popup-cart-wrapper .pf-proceed-checkout-btn:hover,
#pf-popup-cart-wrapper .pf-request-check-stock:hover {
    color: #fff !important;
    text-decoration: none !important;
    border-color: transparent !important;
    cursor: pointer !important;
    transition: all .5s ease-in-out !important;
    background-color: #92a8b4 !important;
}

.request-check-stock-wrapper {
    background-color: #92a8b4 !important;
    margin-top: 35px;
}

.section-bg.pf-product-listing div#list-products-wrapper .you-have-viewed-label {
    margin-top: 17px;
    margin-bottom: -5px;
    display: flex;
    align-content: center;
    justify-content: center;
}

div#pf-product-listing div#list-products-wrapper label.you-have-viewed-label-no-more-products {
    margin-top: 17px;
    margin-bottom: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.pf-popup-cart-loader .pf-checkout-cart-table a.pf-normal-link, .pf-popup-cart-loader .pf-checkout-cart-table a.pf-normal-link:hover {
    color: #000 !important;
    text-decoration: underline;
}

.pf-popup-cart-loader .qty-tbx {
    background-color: #FFF;
    color: #000;
}

    .pf-popup-cart-loader .pf-qty-btn, .pf-popup-cart-loader .qty-tbx:hover {
        border: 1px solid #666;
    }

div#pf-popup-cart-wrapper .pf-popup-cart-loader table#checkout-cart td:first-child {
    width: 70px;
}

.pf-product-list-item .pf-add-cart-wrapper .pf-stock-notify-me a.pf-stock-notify-me-btn {
    color: #A9A9A9;
}

#pf-product-actions-wrapper .pf-stock-notify-me.pf-block a.pf-stock-notify-me-btn {
    color: #A9A9A9;
}

.pf-stock-notify-me-btn {
    color: #A9A9A9 !important;
}

.booking-iframe iframe {
    min-height: 1400px;
}

.productwrapper.pf-product-list-item .wish-list-parent a.hidden, #pf-product-actions-wrapper .pf-favs-wrapper .wish-list-parent a.hidden {
    display: none !important;
}
.featured-products .pf-stock-summary.pf-stock-summary-available {
    color: #aaa;
    font-weight: 400;
}
.featured-products .pf-product-price {
    font-family: Lato,sans-serif;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    color: #363636;
    line-height: 28px;
}
.productwrapper.pf-product-list-item a.add-to-wishlist-btn.isnotfav-icon:hover, .productwrapper.pf-product-list-item .pf-quick-view-btn.pf-btn.pf-btn-sm.pf-btn-primary:hover {
    background-color: #92a8b4 !important;
    color: #fff !important;
}
.productwrapper.pf-product-list-item a.add-to-wishlist-btn.isnotfav-icon, .productwrapper.pf-product-list-item .pf-quick-view-btn.pf-btn.pf-btn-sm.pf-btn-primary {
    text-decoration: none !important;
    background-color: #fff !important;
    color: #92a8b4 !important;
    border-color: #fff !important;
    height: 40px;
    font-size: 16px;
    top: -11px;
    line-height: 18px;
    padding: 10px;
    width: 100%;
    text-transform: none;
    font-family: Lato,sans-serif;
    border-radius: 6px;
    border: 1px solid!important;
    margin-bottom:10px;
}

.productwrapper.pf-product-list-item a.add-to-wishlist-btn.isfav-icon {
    text-decoration: none !important;
    border-color: #92a8b4 !important;
    color: #fff !important;
    height: 40px;
    font-size: 14px;
    top: -11px;
    line-height: 26px;
    padding: 5px 0 0 0;
    width: 100%;
    text-transform: none;
    margin: 0;
    font-family: Lato,sans-serif;
    background-color: #92a8b4 !important;
    border-radius: 6px;
}
#pf-product-actions-wrapper .pf-favs-wrapper .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isfav-icon, #pf-quick-view-page .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isfav-icon {
    text-decoration: none !important;
    border-color: #92a8b4 !important;
    color: #fff !important;
    height: 40px;
    font-size: 14px;
    line-height: 26px;
    padding: 5px 0 0 0;
    width: 258px;
    text-transform: none;
    margin: 0;
    left: 0px;
    font-family: Lato,sans-serif;
    background-color: #92a8b4 !important;
    border-radius: 6px;
}

#pf-product-actions-wrapper .pf-favs-wrapper .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isnotfav-icon, #pf-quick-view-page .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isnotfav-icon {
    text-decoration: none !important;
    background-color: #fff !important;
    color: #92a8b4 !important;
    border-color: #92a8b4 !important;
    height: 40px;
    font-size: 14px;
    line-height: 26px;
    padding: 5px 0 0 0;
    width: 146px;
    text-transform: none;
    margin: 0;
    left: 0px;
    font-family: Lato,sans-serif;
    border-radius: 6px;
}
.productwrapper.pf-product-list-item a.add-to-wishlist-btn.isfav-icon:hover {
    background-color: #fff !important;
    color: #92a8b4 !important;
    border-color: #92a8b4 !important;
}

#pf-product-actions-wrapper .pf-favs-wrapper .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isfav-icon:hover, #pf-quick-view-page .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isfav-icon:hover {
    background-color: #fff !important;
    color: #92a8b4 !important;
}

#pf-product-actions-wrapper .pf-favs-wrapper .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isnotfav-icon:hover, #pf-quick-view-page .wish-list-parent a.pf-icon-link.add-to-wishlist-btn.isnotfav-icon:hover {
    background-color: #92a8b4 !important;
    color: #fff !important;
}


.mbss-image-x-link.downloads-image-link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0;
}

.pf-edit-wishlist {
    color: #fff;
}

.midnav .link-search-div.search-opener {
    font-size: 16px;
    font-weight: 300;
    padding: 49px 16px;
}

    .midnav .link-search-div.search-opener .fa-search {
        color: #92a8b4;
    }

.search-section {
    top: 192px !important;
    background-color: #212121;
}

    .search-section .fa-search,
    .search-section .fa-times {
        color: #92a8b4;
    }
#list-products-wrapper .pf-product-list-options label.product-list-sort-by {
    margin-left: 4px;
}

#list-products-wrapper .pf-product-list-select-btn-wrapper {
    margin-left: 4px;
}

.center-flex.home-img-caption {
    z-index: 1;
}

.pf-product-details-heading {
    margin-top: -12px;
}

#signupForm #create-btn {
    margin-bottom: 20px;
}

.ecs-main-container.reset-password-page {
    padding-bottom: 88px;
}

#pf-currency-selector {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    padding: 0;
    top: 48px;
}
.featured-products .pf-btn.pf-btn-primary.pf-btn-with-qty.pf-add-to-cart-btn, #pf-gallery-image-products .pf-btn.pf-btn-primary.pf-btn-with-qty.pf-add-to-cart-btn {
    border-left: 1px solid #fff !important;
    left: -1px;
}

#create-the-style-content {
    text-align: center;
}

#create-the-style-content .w-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.downloads-txt {
    white-space:normal;
}
.pf-stock-notify-me.pf-block {
    margin-bottom:10px;
}
#pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
    top:230px!important;
}

.ecs-row-full-width.mobile-flex-col-logo .pull-right .phone-header .redactor-toolbar-box {
    position: absolute;
    top: 37px;
    right: 467px;
}
div#pf-gallery-image-product-actions .pf-btn-secondary.pf-add-all-to-wish-list-btn {
    padding: 12px 22px;
    background-color: #92A8B4 !important;
    color: #fff !important;
    border-color: #92A8B4 !important;
}
div#pf-gallery-image-product-actions .pf-btn-secondary.pf-add-all-to-wish-list-btn:hover {
    padding: 12px 22px;
    background-color: #fff !important;
    color: #92A8B4 !important;
    border-color: #fff !important;
}
.pf-product-component-select-title, .pf-product-component-select-stock, .pf-product-component-accordion.pf-product-component-select-data {
    color: #000;
}
.pf-component-select-text {
    color:#363636;
    font-weight:700;
}
.mbss-text .mbss-content p a:hover, .mbss-text div.mbss-content p a:hover {
    color: #fff !important;
}
.homepage-categories {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    justify-content: center;
}
.delivery-dropdown-spacing {
    display:block;
    height:83px;
}

.homepage-categories .flex-child {
    flex: 1 50%;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
}

.homepage-categories .mbss-listItem2-image-wrapper {
    width: 50%;
    display: inline-block;
}
.homepage-categories .home-flex-text {
    width: 50%;
    display: inline-block;
}

.homepage-categories .home-flex-img {
    width: 100% !important;
    height: 100%;
}
.homepage-categories .home-flex-text {
    padding-left: 3.5%;
    padding-right: 7%;
    text-align: start;
    vertical-align:top;
    margin-top:90px;
}
.homepage-categories .home-flex-text a:not(.re-icon):not(.mbss-btn-icon):not(.re-button) {
    padding: 10px 26px;
    color: #92A8B4 !important;
    border: 1px solid #92A8B4;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
}
.homepage-categories .home-flex-text a:not(.re-icon):not(.mbss-btn-icon):not(.re-button):hover {
    color: #fff !important;
    border: 1px solid #92a8b4;
    background-color: #92a8b4
}
.homepage-categories .home-flex-text h4{
    color: #92A8B4;
    font-weight:400;
    margin-top:5px;
}
.homepage-categories .home-flex-text h3 {
    font-size: 24px;
    line-height: 30px;
    color: #363636;
    font-weight:600;
}
.homepage-categories .flex-child:nth-child(4n+3) .mbss-listItem2-image-wrapper, .homepage-categories .flex-child:nth-child(4n+4) .mbss-listItem2-image-wrapper {
    float: right;
}
.homepage-categories .flex-child:nth-child(4n+1) .mbss-listItem2-image-wrapper, .homepage-categories .flex-child:nth-child(4n+2) .mbss-listItem2-image-wrapper {
    float: left;
}
.homepage-categories .flex-child:nth-child(4n+3) .home-flex-text, .homepage-categories .flex-child:nth-child(4n+4) .home-flex-text {
    padding-left: 6%;
    padding-right: 8.5%;
    margin-top:14%;
}
.homepage-categories .move-btn-list {
    display:none;
}
.section-bg {
    background-image: none !important;
    background-position: unset;
}
.home-featured-products-tabs .tab.w-inline-block.w-tab-link, .home-featured-products-tabs .tab.w-inline-block.w-tab-link.w--current, .home-featured-products-tabs .tab.w-inline-block.w-tab-link:hover {
    border: none;
    background-color: #fff!important;
    color: #000!important;
}
.home-featured-products-tabs .tab.w-inline-block.w-tab-link.w--current, .home-featured-products-tabs .tab.w-inline-block.w-tab-link.w--current:hover {
    color: #92a8b4!important;
}
.home-featured-products-tabs .tab.w-inline-block.w-tab-link:first-of-type {
    border-right:1px solid #ccc;
}
.homepage-separator {
    background-color:#000;
    height:2px;
    position:relative;
}
.home-featured-products-tabs .tab-menu.w-tab-menu {
    display: table;
    margin-right: auto;
    margin-left: auto;
}
.featured-products .productwrapper.pf-product-list-item {
    border:none;
}
.slick-slide img {
    display: block;
}
.slick-slider div {
    transition: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.topcategories-slider .pf-slide-item {
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
}
.slick-track:after, .slick-track:before {
    display: table;
    content: '';
}
.topcategories-slider {
    margin-top: 0;
    margin-bottom: 55px;
}
.section.services.midsection .p h3 span {
    font-family: 'Playfair Display';
    font-size: 24px;
    font-weight: 600;
}
.pf-delivery-options-wrapper h3 {
    text-transform:uppercase;
}
.featured-products .productwrapper .product-name.pf-product-name {
    font-family: Lato,sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}
.featured-products .pf-product-list-item .pf-btn.pf-btn-primary.login-modal-btn {
    color: #363636 !important;
    background-color: #fff !important;
    border: 1px solid #363636 !important;
    border-radius: 6px;
    font-size: 16px;
    line-height: 19px;
    height:45px;
}
.featured-products .pf-product-list-item .pf-btn.pf-btn-primary.login-modal-btn:hover {
    color: #fff !important;
    background-color: #363636 !important;
}
.featured-products .pf-product-list-item .pf-product-code {
    font-size: 16px;
    line-height: 19px;
}
.home-featured-products-tabs .pf-slider-button {
    color:#fff;
    opacity:1;
}
.navlink.top.w-clearfix.w-nav-link span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    margin-left: 5px;
    top: 2px;
    position: relative;
}
.featured-products .flex-col .product-match-height.productwrapper.pf-product-list-item .product-code.pf-product-code {
    color: #92a8b4;
}
.section.brandsection .button-wrapper a[href*="Signup"] {
    background-color: #363636;
    border-color: #363636;
    font-size:20px;
    line-height:24px;
    border-radius:7px;
    margin:0;
}
.topcategories-template {
    padding: 0 58px;
}
.topcategories-slider .addListItem2Btn {
    position: relative;
    max-height: 20px;
    bottom: 0;
    right: -102px;
}
.services .service-column.contactus, .services .service-column.tradeaccount, .services .service-column.aboutus {
    padding-left: 0;
    padding-right: 0;
}
.services .service-column div, .services .service-column img{
    width: 100%;
}
.services .service-column .heading {
    top:15%;
    position:absolute;
}
.services .service-column .button-wrapper {
    bottom:7%;
    position: absolute;
}
.section.services.midsection .title-wrapper {
    margin-bottom: 19px;
}
.w-container.section-bg.home-featured-products-tabs {
    display: flex;
    flex-wrap: wrap;
}
.section.logged-in {
    padding-top: 0px !important;
    padding-bottom: 54px;
}
.section.logo-logged-in {
    padding-top: 0;
}
.section.title-wrapper.logo-logged-in {
    padding-bottom: 0;
}
.title-wrapper.logo.topcategories-loggedin {
    margin-top: 0;
    padding-top: 67px;
    margin-bottom: 0;
}
.homepage-bottom-banner {
    padding-top: 82px;
    padding-bottom: 77px;
}
.homepage-bottom-banner .home-image-square-4 .button-wrapper {
    bottom: 75px;
    position: absolute;
    left: 60px;
}
.homepage-bottom-banner .home-image-square-3 img {
    max-height: 295px;
}
.homepage-bottom-banner .home-image-square-3 ul#redactor-toolbar-18 {
    left: -127px !important;
}
.homepage-bottom-banner .ecs-column-3:nth-child(4) ul#redactor-toolbar-20, .homepage-bottom-banner .home-image-square-4 ul#redactor-toolbar-20  {
    left: -83px !important;
}
.homepage-bottom-banner .home-image-square-3 {
    max-height: 295px;
    padding-bottom: 20px;
}
.homepage-bottom-banner .ecs-column-3 {
    margin-right: 0;
}
.homepage-bottom-banner .ecs-column-3:nth-child(1), .homepage-bottom-banner .ecs-column-3:nth-child(2) {
    margin-right: 1.6%;
}
.homepage-bottom-banner .ecs-column-3:nth-child(1) {
    margin-right: 1.6%;
}
.homepage-bottom-banner.home-images-wrapper h2 {
    font-size:36px;
    line-height:46px;
    margin:0;
}
.homepage-bottom-banner.home-images-wrapper .home-image-square-3 .text-wrapper, .homepage-bottom-banner.home-images-wrapper .home-image-square-4 .text-wrapper {
    background-color: #FFFFFF98;
    padding:5px;
}
.homepage-bottom-banner.home-images-wrapper .home-image-square-3 .text-wrapper {
    padding-left: 20px;
    padding-right: 20px;
}
.homepage-bottom-banner.home-images-wrapper .text-wrapper {
    text-align:start;
    text-transform:uppercase;
}
.homepage-bottom-banner.home-images-wrapper .text-wrapper h2{
    text-transform: uppercase;
}
.homepage-bottom-banner.home-images-wrapper .button-wrapper a:not(.re-icon):not(.mbss-btn-icon):not(.re-button), .button-wrapper input, .mbss-banner-text .mbss-content a {
    padding: 10px 20px;
}
.homepage-bottom-banner .home-image-square-1 .text-wrapper {
    bottom: 185px;
    position: absolute;
    left: 125px;
}
.homepage-bottom-banner .home-image-square-2 .text-wrapper {
    bottom: 185px;
    position: absolute;
    left: 88px;
}
.homepage-bottom-banner .home-image-square-1 .button-wrapper{
    bottom: 75px;
    position: absolute;
    left: 125px;
}
.homepage-bottom-banner .home-image-square-2 .button-wrapper {
    bottom: 75px;
    position: absolute;
    left: 88px;
}
.home-image-square-3 .text-wrapper {
    right: 12px;
    top: 60px;
    position: absolute;
}
.homepage-bottom-banner .home-image-square-3 .button-wrapper {
    top: 160px;
    right: 180px;
    position: absolute;
}

.homepage-bottom-banner.home-images-wrapper .home-image-square-4 .text-wrapper {
    position: absolute;
    top:30px;
    padding-left: 60px;
    padding-right:20px;
}
.subscribe-wrapper {
    padding-top: 0;
}
.mbss-subscribe-wrapper input#signup-tbx {
    width: 430px;
    height: 45px;
    background-color: #fff;
    padding-left: 54px;
}
.home-featured-products-tabs a.tab.w-inline-block.w-tab-link.clearance {
    color: #ff0000 !important;
}
.subscribe-wrapper .ecs-column-2:nth-child(1) {
    margin-right: 0;
    width: 39.85%;
}
.subscribe-wrapper .ecs-column-2:nth-child(2) {
    width: 59.15%;
}
.subscribe-wrapper .mbss-subscribe-wrapper {
    padding-bottom: 10px;
    padding-top: 75px;
}
.subscribe-wrapper .footerelementwrapper p {
    line-height: 17px;
    margin-bottom: 0;
}
.footer .footerelementwrapper a {
    text-decoration:none;
    color:#fff;
}
.footer .footerelementwrapper a:hover {
    color: #92a8b4;
}
.footerelementwrapper h3 {
    line-height: 46px;
    margin-top: 0;
}
.section.loggedin .ecs-row .ecs-column-3:nth-child(1) {
    width: 1%;
}
hr.logged-in-ourproducts {
    position: relative;
    width: 100%;
    top: 180px;
    float:left;
}
hr.logged-out-ourproducts {
    position: relative;
    width: 100%;
    top: 60px;
    float:left;
}
.home-featured-products-tabs .tab.w-inline-block.w-tab-link:not(:last-child) {
    border-right: 1px solid #ccc;
}
.subscribe-icon-and-text img {
    position: absolute;
    bottom: 23px;
    left: 14px;
}
.subscribe-icon-and-text {
    padding-right: 20px;
    display: inline-block;
}
.section.logged-in .ecs-row:nth-child(1) .ecs-column-3:not(:last-child) {
    margin-right: 2%;
}
.home-images-wrapper {
    padding-top: 82px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1.09fr;
    grid-template-rows: 1fr 1.09fr;
    column-gap: 1.2%;
    row-gap: 3.75%;
}
.home-image-square-1 {
    grid-column: 1;
    grid-row: 1/3;
    position:relative;
}
.home-image-square-2 {
    grid-column: 2;
    grid-row: 1/3;
    position: relative;
}
.home-image-square-3 {
    grid-column: 3;
    grid-row: 1/3;
    position: relative;
}
.home-image-square-4 {
    grid-column: 3;
    grid-row: 2;
    position: relative;
}
.home-image-square-3 img {
    max-height: 295px;
}
.pf-multi-level-sub-nav .ecs-nav-group {
    padding-bottom: 0 !important;
}
pf-multi-level-sub-nav .ecs-nav-group:last-child {
    padding-bottom: 5px !important;
}
.dropdownlink:after {
    background: #92a8b4 !important;
}
.w-slider-dot {
    background-color: #000;
    width: 25px;
    height: 25px;
}
.w-slider-nav {
    margin-bottom:47px;
}
#myCarousel-1-1 .w-slider-arrow-left, #myCarousel-1-1 .w-slider-arrow-right {
    display:none;
}
.section.title-wrapper.logo {
    padding-top: 50px;
    padding-bottom: 20px;
}
a.heading.w-dropdown-link.pf-multi-level-dropdown.show, a.heading.w-dropdown-link.pf-multi-level-dropdown.show span, a.heading.w-dropdown-link.pf-multi-level-dropdown.show span i{
    color: #92a8b4 !important;
    text-decoration: none;
}
.title-wrapper.ourproducts h1 {
    margin-top: 35px;
}
.title-wrapper.ourproducts {
    margin-bottom: 15px;
}
.brandsection.homepage-logged-out {
    padding:25px 20px;
    margin-top:100px;
}
.brandsection.homepage-logged-out .brandsection {
    padding:0;
}
.social-link {
    color: #92a8b4;
    background-color: #fff;
    border: 1px solid #92a8b4;
    width: 48px;
    height: 48px;
    font-size: 26px;
    line-height: 30px;
}
.mid-footer .mbss-text .mbss-content p a {
    color: #fff !important;
    text-decoration:none;
}
.home-featured-products-tabs .tab-menu .w-tab-link {
    font-size:20px;
    line-height:28px;
}
.bottomfooter.ecs-bottom-container {
    padding:5px;
    background-color:#92a8b4;
    color:#fff;
    text-align:center;
}
.homepage-categories .flex-child .mbss-listItem2-image-wrapper.list-item2-drop:nth-child(2), .featured-dropdown, .mobile-menu-span {
    display: none;
}
.section.logged-in .ecs-column-3{
    width:32%;
}
.home-loggedin-text-wrapper {
    position:absolute;
    top:70px;
    left:50px;
}
.home-loggedin-text-wrapper h4{
    font-size:24px;
    line-height:29px;
    font-weight:500;
}
.home-loggedin-text-wrapper p {
    margin-top: 35px;
}
.home-loggedin-text-wrapper h2 {
    font-size: 36px;
    line-height: 30px;
}
.home-loggedin-text-wrapper a:not(.re-icon):not(.mbss-btn-icon):not(.re-button) {
    padding: 10px 20px;
    color: #fff!important;
    border: 1px solid #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 29px;
    border-radius: 7px;
    font-weight: 500;
}
 .home-loggedin-text-wrapper a:not(.re-icon):not(.mbss-btn-icon):not(.re-button):hover {
    padding: 10px 20px;
    color: #fff !important;
    background-color:#92a8b4;
    border: none;
}
.top-categories-text, .top-categories-button {
    padding-right: 30px;
    padding-left: 47px;
    padding-top:1px;
}
.top-categories-text h2 {
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000;
    font-family: 'Playfair Display',sans-serif;
    font-weight: 400;
    text-align:left;
}
.top-categories-text p {
    margin-bottom: 0;
    padding-top: 0;
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    color: #92A8B4;
}
.top-categories-button {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #92A8B4;
    padding-bottom:30px;
}
.top-categories-button a:not(.re-icon):not(.mbss-btn-icon):not(.re-button) {
    font-size: 20px;
    line-height: 24px;
    border-radius: 6px;
    border: 1px solid #92a8b4;
    text-align: left;
    color: #92A8B4!important;
    text-decoration:none;
    padding:7px;
}
.top-categories-button a:not(.re-icon):not(.mbss-btn-icon):not(.re-button):hover {
    background-color:#92a8b4!important;
    color: #fff !important;
}
    .top-categories-button a:not(.re-icon):not(.mbss-btn-icon):not(.re-button)::before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 200;
        content: "\f178";
        margin-right: 6px;
    }
.topcategories-slider .pf-slide-item {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);
}
.topcategories-slider .slick-track {
    padding-bottom:10px;
}
.topcategories-slider .pf-slide-item:hover div:after {
    width: 100%;
}
.logged-in-topcategories {
    top: 34px;
    position: relative;
    width: 100%;
}
.header-separator {
    display: none;
} .section.home-loggedin-slider {
    display: none;
} .home-images-wrapper .text-wrapper {
    text-transform:uppercase;
} .login-links-mobile {
    display:none;
} .login-links-no-mobile {
    display:inline;
} .pf-table.pf-favs-table a.pf-normal-link {
    color: #363636;
} .pf-table.pf-favs-table a.pf-normal-link:hover {
    color: #92a8b4;
} .ecs-row-full-width.homepage-categories .ecs-row.flex-child {
    max-width:50%;
} .topfooterarea .footerlinkimages .ecs-column-4 {
    text-align:center;
} .pf-multi-level-subcats-wrapper .dropdownlink.w-dropdown-link:hover {
    text-decoration:none!important;
    color:#92a8b4!important;
} .pf-multi-level-subcats-wrapper .dropdownlink.w-dropdown-link span:hover {
    color: #92a8b4 !important;
} .ecs-nav-group .heading.w-dropdown-link span:hover {
    text-decoration: none!important;
    color: #92a8b4!important;
} .ecs-nav-group a.heading.w-dropdown-link:hover {
    text-decoration: none !important;
} .footerlinkimages h4 {
    font-size: 20px;
    line-height: 24px;
    color: #000;
} .footerlinkimages p {
    color: #969696;
    line-height: 19px;
} .footerlinkimages .image-text {
    display:inline-block;
    margin-left:20px;
} .footerlinkimages .image {
    top: 28px;
    position:relative;
    vertical-align:top;
} .lines {
    position:absolute;
    top:unset;
    padding:58px 20px 19px;
    bottom:0;
} .footerlinkimages .ecs-column-4 {
    width:24.25%;
} .pf-qty-btn.pf-qty-btn-minus {
    border-radius:6px 0 0 6px;
} .pf-qty-btn.pf-qty-btn-plus {
    border-radius:0 6px 6px 0;
} .hide-desktop {
    display:none;
} .basket .hide-mobile {
    display:block;
} #pf-product-listing .pf-product-list-item .login-modal-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-login-to-buy-btn {
    line-height: 15px;
} .slider-container {
    text-align:center;
}
#services .create-trade-account .button-wrapper {
    position:relative;
    margin-top:14%;
}
#services .create-trade-account .brandsection.homepage-logged-out {
    margin-top:0;
    padding:0;
}
#services .create-trade-account .brandsection.homepage-logged-out .container img{
    width:unset;
}
#services .create-trade-account .brandsection.homepage-logged-out .container {
    padding:0;
    position:absolute;
    top:14%;
}
.topcategories-slider {
    margin-top:-1px;
}
.pf-quick-view-btn {
    padding: 8px 15px;
    display:inline-block;
    float:left;
    line-height:16px!important;
}
.add-to-wishlist-btn {
    padding: 8px 15px;
    display: inline-block;
}
.pf-product-bottom-btn-wrapper {
    padding: 0 8px;
    position: relative;
}
.featured-products .pf-product-bottom-btn-wrapper {
    padding: 0 34px;
}
#pf-quick-view-page .pf-product-details-info-wrapper {
    text-align: left;
}
#pf-quick-view-page h1.pf-product-details-heading, #pf-quick-view-page h2.table-title.pf-product-details-sub-heading {
    font-size: 20px;
    line-height: 28px;
}
#pf-quick-view-page .pf-product-details-info-wrapper .pf-product-code {
    font-size: 14px;
    line-height: 22px;
}
#pf-quick-view-page #pf-product-actions-wrapper {
    text-align: left;
}
#pf-quick-view-page #pf-product-actions-wrapper .pf-add-to-cart-btn.pf-btn.pf-btn-primary.pf-btn-with-qty {
    display: inline-block;
    padding: 8px 12px;
}
#pf-quick-view-page .pf-disc-table td, .pf-product-list-item .pf-disc-table td {
    background-color: #fff;
    color: #000;
}
#pf-quick-view-page .pf-product-image-thumb.w--current, #pf-quick-view-page .pf-product-image-thumb {
    max-height: 80px;
    padding:0;
}
#pf-quick-view-page .pf-custom-stock-message {
    color: #000;
}
#pf-quick-view-page .pf-product-image-thumb-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
}
#pf-quick-view-page .add-to-wishlist-btn {
    padding: 12px;
    margin-bottom:12px!important;
}
.pf-sale-cart-tag {
    margin-top: 65px;
}
.lg-backdrop.in, .lg-outer {
    z-index:10500!important;
}
#pf-quick-view-page .pf-quick-view-qty-btn-minus, #pf-quick-view-page .pf-qty-btn.pf-quick-view-qty-btn-plus {
    border: 1px solid #666;
    border-radius: 0 6px 6px 0;
}
#pf-quick-view-page .pf-quick-view-qty-btn-minus {
    border-radius: 6px 0 0 6px;
}
#pf-quick-view-page {
    padding-left:0;
    padding-right:0;
}
#pf-quick-view-page .pf-quick-view-add-to-cart-btn.pf-btn.pf-btn-primary {
    padding:8px 22px;
}
#pf-quick-view-page .pf-product-details-sub-heading {
    text-align:left;
}
#pf-quick-view-page .pf-product-information-header {
    font-size: 20px;
    line-height: 28px;
    font-family: 'Playfair Display';
    font-weight: 600;
    color: #363636;
    text-transform:uppercase;
}
#pf-quick-view-page .pf-product-information-item .w-dropdown-toggle {
    padding-left:0;
}
.pf-quick-view-tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #E5E5E5;
    color: #000;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    text-transform: none;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: 120%;
    left: 50%;
    z-index:10;
    margin-left: -60px;
}
.pf-product-list-item .pf-quick-view-btn:hover .pf-quick-view-tooltip-text {
    visibility: visible;
}
.pf-product-list-item .add-to-wishlist-btn:hover .pf-quick-view-tooltip-text {
    visibility: visible;
}

.pf-order-details-item-status {
    display: inline-block;
    margin-top: 6px;
}
#cartNote, .pf-purchaserNote {
    color: #000;
}
select.validity-erroneous {
    border: 2px solid #a82031 !important;
}
#pf-product-listing .pf-stock-notify-me.pf-block a, #pf-product-actions-wrapper .pf-stock-notify-me.pf-block a.pf-stock-notify-me-btn {
    text-decoration: underline;
    color: #f50202 !important;
    font-weight: 600;
}
@media (max-width: 1400px) {
    #services .create-trade-account .brandsection.homepage-logged-out .p {
        font-size: 20px;
        line-height: 28px;
    }
    #services .create-trade-account .brandsection.homepage-logged-out h2 {
        font-size: 26px;
        line-height: 34px;
    }
    .section.brandsection .button-wrapper a[href*=Signup] {
        font-size: 14px;
        line-height: 22px;
    }
    #services .create-trade-account .brandsection.homepage-logged-out .container {
        top: 3%;
    }
}
@media (max-width: 650px) {
    .new-left-bottomlist, .new-right-bottomlist {
    top: 57% !important;
}
} @media (max-width: 540px) {
    .new-left-bottomlist, .new-right-bottomlist {
        top: 52% !important;
    }
    #top-cart-below .proceed {
        width:100%;
    }
} @media (max-width: 420px) {
    .new-left-bottomlist, .new-right-bottomlist {
        top: 284px !important;
    }
} @media (max-width: 390px) {
    .new-left-bottomlist, .new-right-bottomlist {
        top: 54% !important;
    }
} @media (max-width: 220px) {
    .new-left-bottomlist, .new-right-bottomlist {
        top: 40% !important;
    }
} @media (max-width: 470px) {
    .new-left-bottomlist, .new-right-bottomlist {
        top: 48% !important;
    }
    #services .create-trade-account .button-wrapper {
        position: relative;
        margin-top: 7%!important;
    }
} @media (min-width: 667px) {
    .bannerTopHomePageBig {
        display: block;
    }
} @media (max-width: 1300px) {
    .new-left-bottom, .new-right-bottom {
        top: 284px !important;
    }
} @media screen and (min-width: 1025px) {
    .productwrapper {
        min-height: 316px;
    }

    .mega-list {
        width: 100%;
        min-width: 1000px !important;
    }

        .mega-list.w-dropdown-list {
            position: absolute;
            max-height: unset !important;
        }

} @media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait) {
    .ecs-column-4 {
        width: 24%;
        text-align: left;
    }

    .service-column .w-col, .w-col-3, .w-col-4, .w-col-9 {
        width: 33% !important;
    }

    .shrink #bag-opener.nav-link.mid {
        padding: 10px 18px;
    }
}
    /* Overwrite stupid rules in platform-style.css */
    @media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: landscape) {
    .mega-list.w--open {
        margin-top: 0;
    }

    .searchform-wrapper {
        padding: 0 30px 0 30px;
    }

    .nav-menu {
        text-align: right;
    }
} @media (min-width: 1200px) {
    .megalist.w--open.megalist-loggedin {
        left: -571px;
    }

    #list-products-wrapper .pf-product-list-options label.product-list-per-page {
        margin-right: 5px;
    }
} @media (max-width: 1200px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        width: 54px;
    }

    .brandsection._2 {
        background-attachment: scroll;
    }

    .new-left-bottom, .new-right-bottom {
        top: 214px !important;
    }

    .center-flex.home-img-caption {
        min-height: unset;
    }
    .topSectionNav .telNo {
        margin-left:15px;
    }
} @media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait) {
    .nav-menu {
        text-align: right;
    }

    .searchform-wrapper {
        padding: 0 30px 0 30px;
    }

    #pf-product-listing .flex-col {
        width: 32%;
        margin: .5% .5% 0 .5%;
    }

    .ecs-row.filter-select-wrapper {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .pf-product-list-options {
        float: none;
    }

    .pf-checkout-actions .ecs-row-1-2 #makePaymentBtn {
        max-width: 50%;
        float: right;
        margin: 20px auto 0;
    }

    .ecs-row-1-2 {
        width: 100% !important;
    }

    #cartNote.pf-cart-notes-input {
        min-height: unset !important;
    }

    #cartNote {
        min-height: unset;
    }


    #top-cart-below {
        width: 100% !important;
    }

        #top-cart-below.w-col.w-col-12 {
            width: 100%;
        }
} @media screen and (max-width: 1024px) {
    #pf-discount-input-wrapper {
        width: 60%;
        min-width: 300px;
    }

    .header-separator {
        display: block;
        position: absolute;
        border-color: #92A8B4;
        width: 100%;
        top: 100px;
    }
    #header-top .nav.w-nav.navigation {
        padding: 0 38px;
        border-top: none;
    }
    select#product-limit {
        margin-right:5px;
    }
    .pf-product-list-bottom {
        height: 200px;
    }
    .pf-multi-select-all-to-cart.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
        border-left: 1px solid #fff !important;
    }
    #wf-form-Secure-Login-Form .pf-btn.pf-btn-primary {
        margin-bottom: 10px;
    }

    .footer .flex-child {
        width: 23%;
        margin-right: 1%;
    }

    #pf-currency-selector {
        top: 2px;
    }

    #bag-opener.nav-link.mid {
        padding: 13px;
        position: relative;
        top: unset;
        right: initial !important;
        float: none !important;
        line-height: 21px;
    }

    .mega-list.w-dropdown-list {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }

    .brand {
        text-align: center;
        padding-top: 0;
        padding-bottom: 0px;
    }

    .shrink .brand {
        padding-top: 0;
        padding-bottom: 20px;
    }

    .img-overlay {
        height: 380px;
    }

    .cart-table tr td:nth-child(1) {
        width: 14%;
    }
    .searchform-wrapper {
        margin: 15px auto 0;
    }

    .shrink .searchform-wrapper {
        margin: 15px auto 0;
    }

    #pf-product-listing .flex-col {
        width: 32%;
    }

    .booking-iframe iframe {
        min-height: 1650px;
    }

    .featured-products {
        justify-content: center;
    }

        .featured-products .flex-col {
            width: 30%;
            padding: 0;
        }
} @media (max-width: 1024px) {
    #services .create-trade-account .brandsection.homepage-logged-out .container img {
       height:auto;
       max-height:unset;
       min-height:unset;
    }
    #services .create-trade-account .button-wrapper {
        margin-bottom:0;
        margin-top:5px;
    }
    #services .create-trade-account .brandsection.homepage-logged-out .p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom:0;
    }
    #services .create-trade-account .brandsection.homepage-logged-out .p p{
        margin-bottom: 0;
    }
    .home-featured-products-tabs .pf-slider-button {
        color: #363636;
        opacity: 1;
    }
    hr.logged-in-ourproducts {
        top: 140px ;
    }
    hr.logged-out-ourproducts {
        top: 50px;
    }
} @media screen and (max-width: 991px) {
    .w-container {
        max-width: 728px;
    }

    .containerbanner {
        padding-top: 45px;
        padding-bottom: 35px;
    }

    .about-us .section:nth-child(odd) > div > div > div.repeatableText {
        float: none;
        width: 100%;
    }

    .about-us .section:nth-child(even) > div > div > div.repeatableText {
        float: none;
        width: 100%;
    }

    .about-us .section:nth-child(odd) > div > div > div.mbss-listItem2-image-wrapper {
        float: none;
        width: 100%;
    }

    .about-us .section:nth-child(even) > div > div > div.mbss-listItem2-image-wrapper {
        float: none;
        width: 100%;
    }

    .menu {
        position: relative;
        margin-top: 14px;
        padding: 12px;
        border: 1px solid transparent;
        background-color: #fff;
        color: #333;
    }

    #pf-product-listing .flex-col {
        width: 49%;
    }

} @media screen and (max-width: 991px) and (min-width: 768px) {
    #list-products-wrapper.w-col.w-col-9 {
        width: 69% !important;
    }

    #pf-product-listing > .ecs-row > .ecs-column-4 {
        width: 29%;
    }

    .filter-select-wrapper > .ecs-column-2:first-child {
        width: 40%;
        margin-right: 1%;
    }

    .filter-select-wrapper > .ecs-column-2:last-child {
        width: 59%;
    }
} @media (max-width: 990px) {
    .new-left-bottom, .new-right-bottom {
        top: 148px !important;
    }
} @media (max-width: 850px) {
    #wf-form-Secure-Login-Form .pf-btn.pf-btn-primary, #wf-form-Secure-Login-Form .pf-normal-link {
        float: left;
    }
} @media (max-width: 846px) {
    .subscribe-icon-and-text img {
        bottom: 68px;
    }
} @media screen and (max-width: 767px) {
    #pf-discount-input-wrapper {
        width: 100%;
        min-width: 250px;
    }
    #services .create-trade-account .button-wrapper {
        position: relative;
        margin-top: 14%;
    }
    .mm-spn--open .heading {
        font-size: 16px;
        line-height: 19px;
    }
    #pf-product-details-page .breadcrumb a.pf-normal-link, #pf-product-list-page .breadcrumb a.pf-normal-link {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    h1.pf-product-details-heading, h1.productListingMainHeading {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    #pf-product-details-page .breadcrumb {
        padding-left: 0;
    }
    #bag-opener .top-nav-links {
        position: relative;
        top: 55px;
        color: #000;
        display: block;
        font-weight: 400;
    }
    .hide-desktop {
        display: block;
    }
    .basket .hide-mobile {
        display: none;
    }
    .service-column h3 {
        font-size: 30px !important;
        color:#363636;
    }
    .brandsection.homepage-logged-out {
        margin-top: 0;
    }
    .section.title-wrapper.logo h1:first-of-type {
        margin-bottom: 0;
    }
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
        padding: 12px 12px 12px 12px;
        font-size: 20px;
        line-height: 28px;
    }
    .banner-text-large {
        font-size:26px;
        line-height:34px
    }
    .banner-text-medium {
        font-size:18px;
        line-height:26px;
    }
    .delivery-dropdown-spacing {
        display: none;
    }
    .pf-product-list-bottom {
        height:280px;
    }
    .pf-product-list-bottom .pf-multi-select-action-buttons-wrapper {
        position:relative;
    }
    .pf-product-list-bottom #pf-multi-select-slider {
        width:100%;
    }
    .pf-checkout-actions .ecs-row-1-2 #makeConfirmedPaymentBtn {
        max-width: unset;
    }

    .footer .flex-child {
        width: 100%;
        margin-right: 0;
    }

    #wf-form-Secure-Login-Form .pf-btn.pf-btn-primary, #wf-form-Secure-Login-Form .pf-normal-link {
        float: none;
    }

    .w-col {
        width: 100%;
        left: auto;
        right: auto;
    }

    .hidemobile {
        display: none;
    }

    .ecs-column-2 {
        width: 100%;
    }

    .ecs-column-2-full {
        width: 100%;
    }

    .ecs-column-4 {
        text-align: left;
        color: #fff;
    }

    .footerelementwrapper h3 {
        color: #fff;
    }

    .mid-footer h3 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .mobile-flex-col-logo {
        width: 100%;
        text-align: center;
    }

    .menu-button {
        background-color: #fff;
        color: #333;
        font-size: 14px;
        cursor: pointer;
        margin-top: 15px;
        height: 48px;
        width: 48px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-top: 14px;
        padding-left: 17px;
    }

    .mobile-flex-col {
        width: 100%;
        float: left;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #363636;
    }

    #search-form {
        display: block !important;
    }

    .mobile-search-icon {
        display: none;
    }

    #sidenav {
        z-index: 1001;
    }

    #navbackground {
        transition: opacity .5s;
    }
    .mobile-topnav-links {
        width: 50%;
        margin: 0 auto;
    }

    .img-overlay {
        height: 375px;
    }

    .ecs-main-container {
        padding-top: 0 !important;
    }

    .pf-terms-wrapper {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: left;
    }

    .pf-checkout-actions .pf-terms-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    .pf-checkout-actions #makePaymentBtn {
        width: 100%;
        float: none;
        margin: 0 auto;
    }

    .pf-checkout-actions .pf-terms-wrapper h4 {
        margin-top: 0;
        text-align: center;
    }

    .pf-checkout-actions .pf-terms-wrapper p {
        text-align: center;
    }

    .signup-terms-cbx-wrapper label p {
        text-align: left !important;
    }

    .w-tab-menu .pf-tab-header.w--current {
        border: solid 1px #92a8b4;
        margin-bottom: 1px;
        margin-top: 0;
    }

    .w-tab-menu .pf-tab-header {
        border-radius: 0;
        width: 100%;
        border: solid 1px #92a8b4;
        text-align: center;
        margin-top: 1px;
        margin-bottom: 1px;
        background-color: transparent;
        color: #92a8b4;
    }

    .about-us .mbss-listItem2-image.repeatableImage {
        height: unset;
    }

    .pf-checkout-actions .ecs-row-1-2 #makePaymentBtn {
        max-width: 50%;
        float: right;
        margin: 20px auto 0;
    }

    .pf-checkout-actions .ecs-row-1-2 {
        width: 100% !important;
    }

    #pf-currency-selector {
        padding: 13px 5px;
    }

    .featured-products .flex-col {
        width: 50%;
    }
    .containerbanner h1 {
        text-align: center;
    }

    .containerbanner {
        padding: 15px 10px;
    }

    .banner-text-large {
        font-size: 34px;
        line-height: 42px;
    }

    .slide {
        padding-right: 15px;
        padding-left: 15px;
    }

    .tab, .tab.w--current {
        width: 100% !important;
    }

    .brandsection {
        padding-top: 60px;
        padding-bottom: 60px;
        font-size: 18px;
        line-height: 22px;
        padding-left: 0;
        padding-right: 0;
    }

    .brandsection h2 {
        font-size: 37px !important;
        line-height: 37px !important;
        padding-left: 0;
        padding-right: 0;
        letter-spacing: 0px;
    }

    h2 {
        font-size: 30px !important;
        line-height: 38px !important;
    }

    .footer {
        padding: 20px 10px;
    }

    .navigation {
        padding-left: 15px;
    }

    .containerbanner {
        padding: 40px 20px 30px;
    }

    .about-us .section:nth-child(odd) > div > div > div.repeatableText {
        float: none;
        width: 100%;
    }

    .about-us .section:nth-child(even) > div > div > div.repeatableText {
        float: none;
        width: 100%;
    }

    .about-us .section:nth-child(odd) > div > div > div.mbss-listItem2-image-wrapper {
        float: none;
        width: 100%;
        padding-left: 0 !important;
    }

    .about-us .section:nth-child(even) > div > div > div.mbss-listItem2-image-wrapper {
        float: none;
        width: 100%;
    }

    .menu {
        display: inline-block;
        margin-right: 15px;
    }

    .container {
        padding: 0;
    }

    .pf-image-collection .flex-child {
        width: 100%;
    }

    .slider-container {
        background-image: none;
        transition: opacity 2000ms ease-in 0s;
        font-size: 22px;
        line-height: 28px;
        text-shadow: 1px 1px 6px rgba(0,0,0,.8);
        font-weight: 300;
    }

    .slider {
        height: 480px;
    }

    .hidearrow {
        display: none;
    }

    #pf-product-listing .w-col {
        padding-left: 0;
        padding-right: 0;
    }

    .ecs-main-container-nbp {
        padding-top: 0 !important;
    }

    .bottomfooter .ecs-column-2 p {
        font-size: 14px;
    }

    .section {
        padding: 50px 5px;
    }

    .tabs, .w-form.results-filter-wrapper, .w-row.hide {
        display: none;
    }

    .tab-menu {
        padding-bottom: 20px;
    }

    .product-detail-bottom-tabs-section .tab-menu {
        display: inline-block;
    }

    .midnav .pull-left, .midnav .pull-right {
        float: none;
        display: inline-block;
    }

    .sidenav .navlink {
        padding: 10px 18px;
    }

    .featured-products .flex-col {
        width: 48%;
    }
    .homepage-categories .mbss-listItem2-image-wrapper, .homepage-categories .home-flex-text {
        width: 100%;
        display: block;
    }
    .homepage-categories .home-flex-text {
        margin-top: 0 !important;
        padding-top: 70px !important;
        padding-bottom: 70px !important;
        padding-left: 45px !important;
        padding-right: 45px !important;
        display: block !important;
    }
    .homepage-categories .home-flex-text h3 {
        margin-top:0;
    }
    .homepage-categories .flex-child:nth-child(2n+2) .mbss-listItem2-image-wrapper {
        float: none!important;
    }
    .home-featured-products-tabs .tab-menu.w-tab-menu.bgcolor {
        display: none;
    }
    .featured-dropdown {
        display: table;
        width: 100%;
        padding: 0 30px;
    }
    .featured-products .flex-col {
        width: 100%;
        padding: 5px;
    }
    .featured-home-dropdown {
        width:100%;
    }
    .w-container.section-bg.home-featured-products-tabs {
        display: contents;
    }
    .featured-products .flex-col:last-child, .featured-products .flex-col:nth-child(2n+1) {
        margin:0;
    }
    #search-form-mobile {
        display:none;
    }
    .menu-button {
        background-color: #363636;
        color: #fff;
        font-size: 26px;
        cursor: pointer;
        margin-top: 0;
        height: 25px;
        width: 25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 20px;
    }
    .menu-button .fa-bars:before {
        position: absolute;
        top: 8px;
        right: 5%;
    }
    #sidenav, .topSectionNav .telNo {
        display: none;
    }
    #sidenav.mm-spn--light{
        display: block;
    }
    .mobile-menu-span {
        display: inline-block;
        color:#fff;
        padding-top:8px;
    }
    .footerlinkimages .ecs-column-4 {
        width: 100%!important;
        margin-bottom:15px;
        text-align:center;
    }
    .lines {
        padding: 0!important;
        width: 30%;
        border-right: none;
        border-bottom: 1px solid #bfbfbf;
        position: relative!important;
        display: block;
        margin:auto;
        margin-bottom:0;
        margin-top:15px;
    }
    .services#services {
        padding-bottom:0;
    }
    .topfooterarea {
        padding: 25px !important;
        padding-bottom: 10px !important;
    }
    i.freedelivery, i.moneybackguarantee, i.onlinesupport {
        height: unset;
        display: block;
        margin-bottom: 15px;
    }
    .footerlinkimages .ecs-column-4:nth-child(2) .lines {
        display: block!important;
    }
    .footer.ecs-row .flex-child.ecs-column-4:nth-child(2) {
        padding-left: 0!important;
    }
    .footer.ecs-row .flex-child.ecs-column-4 {
        width: 100%!important;
        text-align:center;
    }
    .footer.ecs-row .ecs-column-4:nth-child(1) {
        padding: 0 !important;
        margin-right:0!important;
    }
    #bag-opener.nav-link.mid {
        padding: 0;
        position: absolute;
        left: unset !important;
        right: 0 !important;
        float: unset !important;
        line-height: 21px;
    }
    i.basket.navlink.top.w-clearfix.w-nav-link {
        left: 15px;
        top: 30px;
    }
    .subscribe-wrapper .ecs-column-2:nth-child(1) {
        margin-right: 0;
        width: 100%;
    }
    .subscribe-wrapper .ecs-column-2:nth-child(2) {
        width: 100%!important;
    }
    .subscribe-wrapper .mbss-subscribe-wrapper {
        padding-top: 0;
        padding-bottom:20px;
    }
    .subscribe-icon-and-text img {
        bottom: 78px!important;
    }
    .subscribe-wrapper input#signup-btn {
        max-width:unset;
    }
    .subscribe-icon-and-text {
        padding-right:0;
    }
    .footer.ecs-row .footerelementwrapper.p {
        padding-top:0;
        padding-bottom:20px;
    }
    .subscribe-wrapper input#signup-btn {
        max-width:unset!important;
    }
    .section.home-loggedin-slider {
        display: block;
        text-align: center;
    }
    .section.logged-in {
        display:none;
    }
    .home-loggedin-slide {
        position: relative;
    }
    .home-loggedin-text-wrapper {
        top: 160px;
        left: 0!important;
        right: 0;
    }
    .home-loggedin-slide img {
        margin: auto;
        max-height: 450px;
    }
    .home-images-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 0.45fr 0.45fr;
        row-gap: 1%;
        text-align: center
    }
    .home-image-square-1 {
        grid-column: 1;
        grid-row: 1;
    }
    .home-image-square-2 {
        grid-column: 1;
        grid-row: 2;
    }
    .home-image-square-3 {
        grid-column: 1;
        grid-row: 3;
    }
    .home-image-square-4 {
        grid-column: 1;
        grid-row: 4;
    }
    .homepage-bottom-banner .home-image-square-1 .text-wrapper, .homepage-bottom-banner .home-image-square-2 .text-wrapper, .homepage-bottom-banner .home-image-square-1 .button-wrapper, .homepage-bottom-banner .home-image-square-2 .button-wrapper {
        left: 35%!important;
    }
    .homepage-bottom-banner .home-image-square-3 img, .homepage-bottom-banner .home-image-square-4 img {
        max-width: 370px;
    }
    .homepage-bottom-banner .home-image-square-3 {
        padding-bottom: 0;
    }
    .homepage-bottom-banner.home-images-wrapper .home-image-square-3 .text-wrapper, .homepage-bottom-banner.home-images-wrapper .home-image-square-4 .text-wrapper {
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0;
        text-align: center;
        bottom: unset;
        top: 40%;
        transform: translateY(-50%);
    }
    .homepage-bottom-banner .home-image-square-3 .button-wrapper {
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        text-align: center;
        bottom: unset;
        top: 60% !important;
        transform: translateY(-50%);
    }
    .homepage-bottom-banner .home-image-square-4 .button-wrapper {
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        text-align: center;
        bottom: unset;
        top: 60% !important;
        transform: translateY(-50%);
    }
    .homepage-bottom-banner.home-images-wrapper .home-image-square-3 .text-wrapper, .homepage-bottom-banner.home-images-wrapper .home-image-square-4 .text-wrapper {
        background-color: transparent;
        padding-left:0;
        padding-right:0;
    }
    .homepage-bottom-banner .home-image-square-1 .button-wrapper {
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        text-align: center;
        bottom: unset;
        top: 55%!important;
        transform: translateY(-50%);
    }
    .homepage-bottom-banner .home-image-square-2 .button-wrapper {
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        text-align: center;
        bottom: unset;
        top: 55% !important;
        transform: translateY(-50%);
    }
    .section.title-wrapper.logo-logged-in h1:first-of-type {
        margin-bottom:0;
    }
    .homepage-bottom-banner .home-image-square-1 .text-wrapper, .homepage-bottom-banner .home-image-square-2 .text-wrapper, .homepage-bottom-banner .home-image-square-1 .button-wrapper, .homepage-bottom-banner .home-image-square-2 .button-wrapper {
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0;
        text-align: center;
        bottom: unset;
        top: 40%;
        transform: translateY(-50%);
    }
    .logged-in-ourproducts, .login-links-no-mobile, .logged-out-ourproducts {
        display: none;
    }

    .login-links-mobile {
        display: block;
    }
    .allmenubuttons {
        float: left;
    }
    .topcategories-template {
        padding: 0 25px;
    }
    .top-categories-text {
        font-size: 26px;
        line-height: 34px;
    }
    #sidenav {
        background-color:#92a8b4;
        text-transform:uppercase;
    }
    #sidenav .pf-highlight-nav {
        color:#000;
        font-weight: 700;
    }
    #sidenav .fas.fa-bars.fa-lg {
        display:none;
    }
    #sidenav.mm-spn li:before {
        color:#000!important;
    }
    #sidenav .w--current {
        color:#363636!important;
    }
    #sidenav .dropdownlink.w-dropdown-link.w--current span{
        color: #92a8b4!important;
        text-decoration:none;
    }
    #sidenav .dropdownlink.w-dropdown-link.w--current {
        color: #92a8b4 !important;
        text-decoration: none;
    }
    #sidenav a.heading.w-dropdown-link:hover,.dropdownlink.w-dropdown-link:hover {
        text-decoration: none!important;
    }
    .mm-spn--open li ul {
        background-color: #fff;
    }
    .featured-home-dropdown {
        -webkit-appearance: menulist;
        -moz-appearance: initial;
    }
    .ecs-row.homepage-bottom-banner.homepage-row.home-images-wrapper {
        padding-top:0;
    }
    .subscribe-wrapper .ecs-row {
        display:table;
    }
    .mm-spn--open .dropdownlink.w-dropdown-link {
        padding:5px;
    }
    .mm-spn--open .dropdownlink.w-dropdown-link span{
       color:#000;
       padding:5px;
    }
    .home-loggedin-slider {
        padding:0;
    }
    .home-loggedin-slide div:first-of-type {
        width:100%;
    }
} @media (max-width: 765px) {
    .home-featured-products-tabs .pf-slider-button {
        color: #fff;
        opacity: 1;
    }
    .new-left-bottom, .new-right-bottom {
        top: 59% !important;
    }

    #pf-gallery-image-products .flex-col {
        width: 50% !important;
    }

    #addresses-tab-content .align-center, #info-tab-content .align-center {
        text-align: left;
    }
    #checkout .ecs-row {
        display:table;
    }
} @media (max-width: 479px) {
    .pf-quick-order-top {
        padding: 3px;
    }

    .banner-text-large {
        font-size: 27px;
        line-height: 38px;
    }

    h1 {
        font-size: 34px;
        line-height: 42px;
    }

    h2 {
        font-size: 28px !important;
        line-height: 36px !important;
    }
    .downloads-wrapper h2 {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    h3 {
        font-size: 22px !important;
        line-height: 30px !important;
    }

    slider {
        height: 500px;
    }
    #pf-currency-selector {
        font-size: 17px;
    }

    .phone-link {
        display: none;
    }

    .phone-link-mobile {
        display: inline-block;
    }

    .pf-quick-order-wrapper {
        width: 100% !important;
    }

    .phone-link-mobile {
        display: inline-block;
    }

    .pf-checkout-actions .ecs-row-1-2 #makePaymentBtn {
        max-width: 100%;
        float: right;
        margin: 20px auto 0;
    }

    .featured-products .flex-col {
        width: 100%;
    }
    .w-col {
        width: 100%;
    }

    .w-container {
        max-width: none;
        padding: 0 10px;
    }

    .containerbanner {
        padding: 15px 10px;
    }

    .hidemobile {
        display: none;
    }

    .flex-item {
        justify-content: space-between;
        width: 95%;
    }

    .featured-products .flex-col:first-child {
        margin-left: 0 !important;
    }

    .featured-products .flex-col:last-child, .featured-products .flex-col:nth-child(3n+4) {
        margin-right: 0 !important;
    }

    .featured-products .flex-col:last-child, .featured-products .flex-col:nth-child(4n+4) {
        margin-right: 0 !important;
    }

    .section-bg.tab-pane .title-wrapper {
        display: none !important;
    }

    .center-flex {
        top: 50%;
        transform: translateY(-50%);
        max-height: 60%;
    }

    a.pf-normal-link {
        display: inline-block;
    }

    .pf-reset-pass {
        float: none;
    }

        .pf-reset-pass.pf-normal-link {
            margin-top: 10px;
        }

    .title-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
    }

    .new-right-bottomlist {
        right: 60px;
    }

    .pf-list-item-img {
        width: 100%;
    }

    .ecom-filter-group {
        padding-top: 0;
    }

    .pf-filter-item {
        font-size: 15px;
        text-transform: none;
    }

    #pf-cart-wrapper {
        overflow-x: scroll !important;
    }
    .featured-products .new-left-bottomlist.pf-sale-product-tag, .featured-products .new-right-bottomlist.pf-new-product-tag, #pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
        top: 84% !important;
    }

    #list-products-wrapper .new-left-bottomlist.pf-sale-product-tag, #list-products-wrapper .new-right-bottomlist.pf-new-product-tag {
        top: 80% !important;
    }

    .pf-btn.pf-btn-sm.pf-btn-primary.pf-request-check-stock {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        margin: 0;
    }

    .edit-wishlist-name-btn i:last-child {
        display: none;
    }

    .delete-wishlist-btn span {
        display: none;
    }

    .fav-item-price.product-price {
        text-align: center;
    }
} @media screen and (max-width: 380px) {
    .brand img {
        max-width: 85%;
    }

    #pf-gallery-image-products .flex-col {
        width: 100% !important;
    }

    .ecs-row {
        display: block;
    }
    .ecs-row.footerlinkimages {
        display:table;
    }
    .ecs-row.homepage-bottom-banner.homepage-row.home-images-wrapper {
        display: grid;
    }
    #signupForm .ecs-row, #list-products-wrapper .ecs-row {
        display: table;
    }
    .filter-select-wrapper {
        display: table;
    }

    .qty-tbx {
        margin-bottom: 0px;
    }
    #list-products-wrapper .pf-product-list-select-btn-wrapper {
        margin-bottom: 80px;
    }
} @media screen and (max-width: 500px) {
    .remodal-is-opened #pf-popup-cart-wrapper .cart-buttons-popup .pf-continue-shopping-cart, .remodal-is-opened #pf-popup-cart-wrapper .cart-buttons-popup .pf-proceed-checkout-btn {
        width: 100%;
        text-align: center;
        margin-right: 0;
    }

    #pf-gallery-image-products .flex-col {
        width: 100% !important;
    }
} @media screen and (min-width: 768px) {
    /* Override platform-style default shrink behaviour */
    .shrink .nav-link.nav-dropdown.w--open,
    .shrink .nav-link.nav-dropdown {
        padding-top: 0 !important;
        padding-bottom: 28px;
    }
    .nav-link.nav-dropdown {
        position: relative;
    }

    .header-link i {
        font-size: 14px;
        margin-right: 5px;
    }

    #bag-opener i {
        font-size: 14px;
    }   
} @media screen and (max-width: 1061px) and (min-width: 1024px) {
    .booking-iframe iframe {
        min-height: 1650px;
    }
} @media screen and (max-width: 320px) {
    .booking-iframe {
        padding: 0;
    }
    #top-cart-holder {
        padding:5px;
    }
    #top-cart-below, #top-cart-title {
        padding:0;
    }
    table.cart-table tbody > tr > td, table.cart-table tbody > tr > th {
        padding: 3px;
        text-align: left;
    }
    .ecs-row-container.cart-buttons-popup {
        padding-left:0;
        padding-right:0;
    }
    .cart-table tr td:nth-child(1) {
        text-align: center;
    }
}
    /*Featured Products*/
@media (max-width: 1300px) {
    #pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
        top: 275px !important;
    }
} 
@media (max-width: 1200px) {
    .featured-products .new-left-bottomlist.pf-sale-product-tag, .featured-products .new-right-bottomlist.pf-new-product-tag, #pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
        top: 225px !important;
    }
    #list-products-wrapper .new-left-bottomlist.pf-sale-product-tag, #list-products-wrapper .new-right-bottomlist.pf-new-product-tag {
        top: 74% !important;
    }
    .subscribe-wrapper {
        padding-right:20px;
        padding-left:20px;
    }
    .subscribe-wrapper input#signup-btn {
        max-width:200px;
    }
    .mbss-subscribe-wrapper input#signup-tbx {
        width:350px;
    }
    .footerelementwrapper p {
        overflow:hidden;
    }
} @media (max-width: 1024px) {
    .featured-products .pf-product-list-item .pf-btn.pf-btn-primary.login-modal-btn {
        color: #fff !important;
        background-color: #363636 !important;
        border: 1px solid #363636 !important;
    }
    .ecs-row-full-width.homepage-categories .ecs-row.flex-child {
        max-width: 100%;
    }
    .subscribe-wrapper .ecs-column-2:nth-child(2) {
        width: 60.1%;
    }
    #list-products-wrapper .new-left-bottomlist.pf-sale-product-tag, #list-products-wrapper .new-right-bottomlist.pf-new-product-tag {
        top: 220px !important;
    }
    .homepage-categories .flex-child .mbss-listItem2-image-wrapper.list-item2-drop:nth-child(1) {
        display: none;
    }
    .homepage-categories .flex-child .mbss-listItem2-image-wrapper.list-item2-drop:nth-child(2) {
        display: inline-block;
    }
    .homepage-categories .flex-child {
        flex: 1 100%;
    }
    .homepage-categories .flex-child:nth-child(4n+3) .mbss-listItem2-image-wrapper, .homepage-categories .flex-child:nth-child(4n+4) .mbss-listItem2-image-wrapper {
        float: left;
    }
    .homepage-categories .flex-child:nth-child(2n+2) .mbss-listItem2-image-wrapper{
        float: right;
    }
    .homepage-categories .home-flex-text, .homepage-categories .flex-child:nth-child(4n+3) .home-flex-text, .homepage-categories .flex-child:nth-child(4n+4) .home-flex-text {
        padding-left: 3.5%;
        padding-right: 7%;
        margin-top: 40px;
    }
    .flex-parent.ecs-row-full-width.homepage-categories h4 {
        font-size:16px;
        line-height:20px;
    }
    .featured-products .flex-col {
        margin: 0 0.15% 0 0.15%;
        width: 49.5%;
    }
    .w-container.section-bg.home-featured-products-tabs {
        display: contents;
    }
    .brand {
        position: absolute;
        max-width: 245px;
        top:5px;
    }
    #search-form {
        margin-top:60px;
    }
    #prod-search-tbx {
        height:30px;
        padding-left:0;
    }
    .nav-link {
        padding: 11px 12px 10px 12px;
    }
    #prod-search-btn {
        height:35px;
        width:35px;
        padding:8px;
        top:5px;
    }
    .bottomfooter, .mid-footer {
        padding-left: 0;
        padding-right: 0;
    }
    .topfooterarea {
        padding-left:70px;
        padding-right:70px;
        padding-bottom:70px
    }
    .footer.ecs-row .flex-child.ecs-column-4 {
        width: 23.3%;
    }
    .footer.ecs-row .flex-child.ecs-column-4:nth-child(2) {
        padding-left:30px;
    }
    .footer.ecs-row .ecs-column-4:nth-child(1) {
        width: 100%;
        text-align: center;
        padding-left: 100px;
        padding-right: 100px;
        margin-bottom:30px;
    }
    .footerlinkimages .ecs-column-4 {
        width:49%;
    }
     .footerlinkimages .ecs-column-4:nth-child(1), .footerlinkimages .ecs-column-4:nth-child(2) {
         margin-bottom: 20px;
     }
    .footerlinkimages .ecs-column-4:nth-child(2) .lines {
        display: none;
    }
    .lines {
        position: absolute;
        right: 0;
        padding: 55px 20px 19px;
    }
    #services .service-column img {
        height: fit-content;
        max-height: 400px;
        min-height:275px;
    }
    #bag-opener.nav-link.mid {
        top: -90px;
    }
    .basket.navlin.top {
        padding: 0;
        top: -3px;
        left: 0;
    }
    .allmenubuttons .navlink {
        padding: 10px 10px;
    }
    .subscribe-wrapper input#signup-btn {
        max-width: 140px;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 20px;
    }
    .subscribe-icon-and-text {
        width: 75.8%;
    }
    .mbss-subscribe-wrapper input#signup-tbx {
        width: 100%;
    }
    .home-loggedin-text-wrapper {
        left: 10%;
    }
    .homepage-bottom-banner .home-image-square-1 .text-wrapper, .homepage-bottom-banner .home-image-square-2 .text-wrapper {
        left: 60px;
    }
    .homepage-bottom-banner .home-image-square-1 .button-wrapper, .homepage-bottom-banner .home-image-square-2 .button-wrapper {
        left: 60px;
    }
    .homepage-bottom-banner.home-images-wrapper h2 {
        font-size: 26px;
        line-height: 34px;
    }
    .homepage-bottom-banner .home-image-square-4 .button-wrapper {
        bottom: 10px;
        left: 62px;
    }
    .home-image-square-3 .text-wrapper {
        right: 0;
        top: 30px;
    }
    .homepage-bottom-banner .home-image-square-3 .button-wrapper {
        top: 100px;
        right: 107px;
    }
    .home-featured-products-tabs img {
        margin:auto;
    }
    .home-loggedin-text-wrapper h2 {
        font-size: 30px !important;
        line-height: 38px !important
    }
    .featured-products .flex-col:last-child, .featured-products .flex-col:nth-child(3n+4) {
        margin-right: 0;
    }
    .home-featured-products-tabs .tab-menu .w-tab-link {
        font-size: 16px;
        line-height: 24px;
    }
} 
@media (max-width: 990px) {
    #pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
        top: 130px !important;
    }

    .productOptions, select#node, select#product-sort {
        min-width: unset;
    }
} @media (max-width: 846px) {
    .subscribe-icon-and-text {
        width: 100%;
        padding-right: 0;
    }
    .subscribe-wrapper input#signup-btn {
        max-width: 100%;
    }
} @media (max-width: 765px) {
    .featured-products .pf-product-list-item .pf-btn.pf-btn-primary.login-modal-btn {
        color: #363636 !important;
        background-color: #fff !important;
        border: 1px solid #363636 !important;
    }
    .featured-products .new-left-bottomlist.pf-sale-product-tag, .featured-products .new-right-bottomlist.pf-new-product-tag, #pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
        top: 80% !important;
    }
    .fav-item-price.product-price {
        text-align: center;
    }
} @media (max-width: 390px) {
    .featured-products .new-left-bottomlist.pf-sale-product-tag, .featured-products .new-right-bottomlist.pf-new-product-tag, #pf-gallery-image-products .new-left-bottomlist.pf-sale-product-tag, #pf-gallery-image-products .new-right-bottomlist.pf-new-product-tag {
        top: 78% !important;
    }

    .product-match-height.productwrapper.pf-product-list-item {
        margin-top: 20px;
    }
} @media (max-width: 360px) {
    #pf-my-account-page .pf-add-to-cart-btn, .pf-detail-view-add-cart-wrapper .pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
        border-left: 1px solid #fff !important;
    }
    #pf-my-account-page .pf-all-wishlists .pull-right .pf-btn.pf-btn-primary.pf-btn-sm {
        width:100%;
    }
} @media (max-width: 767px) {
    .w-container.section-bg.home-featured-products-tabs .w-tab-menu, .w-container.section-bg.home-featured-products-tabs .w-tab-pane, .w-container.section-bg.home-featured-products-tabs .w-tabs {
        position: static;
    }
    .header-separator {
        display: none;
    }
    .homepage-categories .flex-child:nth-child(4n+3) .mbss-listItem2-image-wrapper, .homepage-categories .flex-child:nth-child(4n+4) .mbss-listItem2-image-wrapper {
        float: none;
    }
    .homepage-categories .flex-child:nth-child(4n+1) .mbss-listItem2-image-wrapper, .homepage-categories .flex-child:nth-child(4n+2) .mbss-listItem2-image-wrapper{
        float:none;
    }
}
#pf-product-listing .flex-item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}
#pf-product-listing .flex-col {
    width: 100%;
    margin: .5% .5% 0 .5%;
}
.errormessage-highlight.emailFormMessage {
    display: none;
}
@media screen and (max-width: 1024px){
    #pf-product-listing .flex-item {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 480px) {
    #pf-product-listing .flex-item {
        grid-template-columns: repeat(1, 1fr);
    }
}
#contact-us-email-form #name, #contact-us-email-form #email, #contact-us-email-form #Phone, #contact-us-email-form #Message {
    color: #000;
}
.featured-products .productwrapper .pf-stock-notify-me.pf-block a {
    text-decoration: underline;
    color: #f50202 !important;
    font-weight: 600;
}
#checkout-form div.ecs-row::after {
    content: '';
    clear: both;
    display: table;
}
.webui-popover.top.in {
    z-index:9999999!important
}.about-us .booking-iframe iframe {
    min-height: 1790px;
}
